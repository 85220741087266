import * as React from 'react';
function SvgMailIcon(props) {
  return (
    <svg width={12} height={9} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.848 2.774c-.152.103-.61.405-1.371.911-.763.506-1.349.893-1.755 1.166l-.283.195c-.146.101-.265.182-.361.244a6.044 6.044 0 01-.347.208 2.113 2.113 0 01-.385.173c-.12.038-.232.058-.335.058h-.015a1.13 1.13 0 01-.335-.058 2.113 2.113 0 01-.384-.173 5.327 5.327 0 01-.348-.208 15.835 15.835 0 01-.36-.244l-.284-.195c-.41-.273-1.457-.964-3.134-2.077a3.097 3.097 0 01-.816-.786 1.643 1.643 0 01-.328-.965C.007.741.113.501.326.3.532.1.783 0 1.074 0h9.854c.345 0 .61.11.795.331.185.222.277.5.277.833 0 .269-.122.56-.368.873a3.303 3.303 0 01-.784.737z"
        fill="#fff"
      />
      <path
        d="M.67 3.459c1.464.947 2.576 1.681 3.335 2.205.254.18.46.318.62.419.16.1.368.201.633.307.262.105.509.156.736.156h.014c.228 0 .474-.051.737-.156.262-.106.473-.206.633-.307.16-.1.366-.24.619-.419.603-.42 1.712-1.157 3.328-2.205.253-.163.478-.35.675-.558v5.076a.969.969 0 01-.314.723c-.209.2-.462.3-.758.3H1.072c-.296 0-.547-.1-.758-.3A.965.965 0 010 7.977V2.9c.195.204.417.39.67.558z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgMailIcon;
