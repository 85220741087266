import React, { forwardRef, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Calendar from 'react-calendar';
import './calendar_styles.css';
import { Button, LoadingSpinner } from 'components';
import { format, parse, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { AnimatePresence, m, motion } from 'framer-motion';
import { useWindowSize, useScrollLock } from 'hooks';
import { deviceDetect } from 'react-device-detect';
import { useEffect } from 'react';

const preferredDateRegex =
  /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/;

const formInitialState = ({ activityInfo, location }) => ({
  firstName: '',
  surname: '',
  email: '',
  phoneNumber: '',
  company: '',
  message: '',
  addToMailingList: 'No',
  Website_Referral_URL: location.href,
  Activity_Title: activityInfo.title,
  Product_ID: activityInfo.productId,
  numberOfParticipants: 25,
  formatOfDelivery: {
    inPerson: 'No',
    online: 'No'
  },
  preferredDate: ''
});

const GetQuoteModal = forwardRef(({ className, activityInfo, location }, ref) => {
  const [formValues, setFormValues] = useState(formInitialState({ activityInfo, location }));
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [calendarValue, setCalendarValue] = useState(null);
  const [userIsInteractingWithCalendar, setUserIsInteractingWithCalendar] = useState(false);
  const [generatingQuoteStatus, setGeneratingQuoteStatus] = useState({
    sending: false,
    error: false
  });
  const { unlockScroll } = useScrollLock();
  const { windowWidth } = useWindowSize();
  const onMobile = windowWidth < 768;

  const formatOfDeliveryInPersonRef = useRef();
  const formatOfDeliveryOnlineRef = useRef();
  const preferredDateRef = useRef();

  const {
    firstName,
    surname,
    email,
    phoneNumber,
    company,
    message,
    addToMailingList,
    numberOfParticipants,
    formatOfDelivery,
    preferredDate,
    Website_Referral_URL,
    Activity_Title,
    Product_ID
  } = formValues;

  const formatOfDeliverySelectionIsRequired =
    activityInfo.formatOfDeliveryAndEventSupport.some((format) => format.includes('In Person')) &&
    activityInfo.formatOfDeliveryAndEventSupport.some((format) => format.includes('Virtual'));

  useEffect(() => {
    if (
      formatOfDeliverySelectionIsRequired &&
      formatOfDeliveryInPersonRef.current &&
      formatOfDeliveryOnlineRef.current
    ) {
      const formatOfDeliveryInputRefs = [formatOfDeliveryInPersonRef, formatOfDeliveryOnlineRef];
      if (formatOfDelivery.inPerson === 'No' && formatOfDelivery.online === 'No') {
        formatOfDeliveryInputRefs.forEach((format) => {
          format.current.setAttribute(
            'oninvalid',
            "this.setCustomValidity('Please select a format of delivery.')"
          );
        });
      } else {
        formatOfDeliveryInputRefs.forEach((format) => {
          format.current.setAttribute('oninvalid', "this.setCustomValidity('')");
        });
      }
    }
  }, [
    formatOfDeliverySelectionIsRequired,
    formatOfDelivery,
    formatOfDeliveryInPersonRef,
    formatOfDeliveryOnlineRef
  ]);

  useEffect(() => {
    if (calendarValue) {
      setFormValues((currentValues) => ({
        ...currentValues,
        preferredDate: format(calendarValue, 'dd/MM/yyyy')
      }));
    }
  }, [calendarValue]);

  const handleCheckboxValue = (checkboxIsChecked) => (checkboxIsChecked ? 'Yes' : 'No');

  const handleChange = (e) => {
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name === 'numberOfParticipantsSlider' ? 'numberOfParticipants' : e.target.name]:
        e.target.type === 'checkbox' ? handleCheckboxValue(e.target.checked) : e.target.value
    }));
  };

  const handleFormatOfDeliveryChange = (e) => {
    setFormValues((currentValues) => ({
      ...currentValues,
      formatOfDelivery: {
        ...currentValues.formatOfDelivery,
        [e.target.name]: handleCheckboxValue(e.target.checked)
      }
    }));
  };

  const handleModalClick = (e) => {
    const dialog = ref.current;
    const rect = dialog.getBoundingClientRect();

    const isInDialog =
      rect.top <= e.clientY &&
      e.clientY <= rect.top + rect.height &&
      rect.left <= e.clientX &&
      e.clientX <= rect.left + rect.width;

    if (!isInDialog) {
      unlockScroll();
      dialog.close();
    }
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (
      firstName &&
      surname &&
      email &&
      company &&
      message &&
      numberOfParticipants >= 2 &&
      (!formatOfDeliverySelectionIsRequired ||
        formatOfDelivery.inPerson === 'Yes' ||
        formatOfDelivery.online === 'Yes')
    ) {
      setGeneratingQuoteStatus({
        sending: true,
        error: false
      });
    } else {
      return;
    }

    let fees = {};

    // Removes any fees that are 0 or "". This can happen if a format once had fees
    // but then those fees were later removed.
    for (const format in activityInfo.fees) {
      if (
        activityInfo?.fees[format]?.setupFee &&
        activityInfo?.fees[format]?.setupFee !== 0 &&
        (!activityInfo?.fees[format]?.perPlayerFee ||
          (activityInfo?.fees[format]?.perPlayerFee &&
            activityInfo?.fees[format]?.perPlayerFee !== 0))
      ) {
        fees[format] = activityInfo.fees[format];
      }
    }

    const convertCamelCaseToTitleCase = (str) =>
      str
        .replace(/([A-Z])/g, ' $1') // insert a space before all caps
        .replace(/^./, (str) => str.toUpperCase()); // uppercase the first character

    // Calculates the total fee depending on how many particpants the user has entered, and adds
    // the event format type as a property on the fee object.
    for (const format in fees) {
      const totalFee = fees[format]?.perPlayerFee
        ? fees[format]?.setupFee + fees[format]?.perPlayerFee * numberOfParticipants
        : fees[format]?.setupFee;
      fees[format]['totalFee'] = totalFee;
      fees[format]['type'] = convertCamelCaseToTitleCase(format);
    }

    // Convert to an array so we iterate over the fees in the email template.
    fees = Object.values(fees);

    // Format prices, i.e. add '€' symbol and commas where appropriate.
    const currencyFormatter = new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    fees = fees.map(({ type, setupFee, perPlayerFee, totalFee }) => {
      const fee = {
        type,
        setupFee: currencyFormatter.format(setupFee),
        totalFee: currencyFormatter.format(totalFee)
      };

      if (perPlayerFee) {
        fee['perPlayerFee'] = currencyFormatter.format(perPlayerFee);
      }

      return fee;
    });

    // Filter out any irrelevant fees, e.g. if the user has stated that they're only interested in
    // the 'online' event format, then we don't include any 'inPerson' fees in the quote emailed
    // to them.
    if (formatOfDeliverySelectionIsRequired) {
      fees = fees.filter(
        ({ type }) =>
          (formatOfDelivery.inPerson === 'Yes' && type.includes('Person')) ||
          (formatOfDelivery.online === 'Yes' && type === 'Fully Virtual')
      );
    }

    const deviceInfo = deviceDetect();

    const dynamicEventsGetQuoteData = {
      firstName,
      surname,
      email,
      phoneNumber
    }

    window.sessionStorage.setItem(
      'dynamicEventsGetQuoteData',
      JSON.stringify(dynamicEventsGetQuoteData)
    );

    await fetch(`/.netlify/functions/send-quote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fees,
        firstName,
        email,
        numberOfParticipants,
        activityPageUrl: Website_Referral_URL,
        activityTitle: Activity_Title,
        isCustomMadeSolution: activityInfo.title === 'Custom Made Solutions',
        brochureUrl: activityInfo.brochureUrl,
        deviceInfo
      })
    })
      .then((response) => response.json())
      .then((data) => setGeneratingQuoteStatus({
        sending: false,
        error: false
      }))
      .catch((error) => {
        console.error(error.message);
        setGeneratingQuoteStatus({
          sending: false,
          error: true
        });
      });
  };

  return (
    <Modal onClick={handleModalClick} className={className} ref={ref}>
      <h3>Get Instant Quote</h3>
      <h2>{activityInfo.title}</h2>
      <Form
        onSubmit={handleSubmit}
        action="https://www2.dynamicevents.ie/l/405332/2022-10-13/237ykv2"
        method="post"
        enctype="application/x-www-form-urlencoded">
        <section>
          <div hidden>
            <Label htmlFor="pardot_extra_field">
              Don't fill this out:
              <Input type="text" id="pardot_extra_field" name="pardot_extra_field" />
            </Label>
          </div>
          <div hidden>
            <Label htmlFor="Product_ID">
              Product ID
              <Input type="text" id="Product_ID" name="Product_ID" value={Product_ID} readOnly />
            </Label>
          </div>
          <div hidden>
            <Label htmlFor="Website_Referral_URL">
              Website Referral URL
              <Input
                type="text"
                id="Website_Referral_URL"
                name="Website_Referral_URL"
                value={Website_Referral_URL}
                readOnly
              />
            </Label>
          </div>
          <div hidden>
            <Label htmlFor="Activity_Title">
              Activity Title
              <Input
                type="text"
                id="Activity_Title"
                name="Activity_Title"
                value={Activity_Title}
                readOnly
              />
            </Label>
          </div>
          <Label>
            First Name<Asterisks>*</Asterisks>
            <Input
              aria-label="Enter your first name"
              name="firstName"
              onChange={handleChange}
              placeholder="Enter your first name"
              required
              type="text"
              value={firstName}
            />
          </Label>
          <Label>
            Surname<Asterisks>*</Asterisks>
            <Input
              aria-label="Enter your surname"
              name="surname"
              onChange={handleChange}
              placeholder="Enter your surname"
              required
              type="text"
              value={surname}
            />
          </Label>
          <Label>
            Company E-mail<Asterisks>*</Asterisks>
            <Input
              aria-label="Enter your company email"
              name="email"
              onChange={handleChange}
              placeholder="Enter company email"
              required
              type="email"
              value={email}
            />
          </Label>
          <Label>
            Phone Number
            <Input
              aria-label="Enter your phone number"
              name="phoneNumber"
              onChange={handleChange}
              placeholder="Enter phone number"
              type="tel"
              value={phoneNumber}
            />
          </Label>
          <Label>
            Company<Asterisks>*</Asterisks>
            <Input
              aria-label="Enter your company name"
              name="company"
              onChange={handleChange}
              placeholder="Enter company name"
              required
              type="text"
              value={company}
            />
          </Label>
        </section>
        <section>
          <Participants>
            <Label>
              Number of Participants:
              <ParticipantsInput
                aria-label="Enter number of participants"
                name="numberOfParticipants"
                onChange={handleChange}
                required
                type="number"
                min={2}
                max={201}
                placeholder={25}
                value={numberOfParticipants == 201 ? 200 : numberOfParticipants}
                numberOfParticipants={numberOfParticipants}
              />
              <div style={{ position: 'relative' }}>
                <ParticipantsRangeInput
                  aria-label="Slider for number of participants"
                  name="numberOfParticipantsSlider"
                  onChange={handleChange}
                  required
                  type="range"
                  min={2}
                  max={201}
                  placeholder={20}
                  value={numberOfParticipants}
                />
                <CustomRangeTrack numberOfParticipants={numberOfParticipants} />
              </div>
            </Label>
            <span>0</span>
            <span>200+</span>
          </Participants>
          <div
            hidden={!formatOfDeliverySelectionIsRequired}
            style={{ position: 'relative', marginTop: onMobile ? '2.4rem' : '1.65rem' }}>
            <CheckboxParentPseudoLabel>
              Format of Delivery<Asterisks>*</Asterisks>
            </CheckboxParentPseudoLabel>
            <FormatOfDelivery>
              <CheckboxLabel style={{ marginRight: '2rem' }}>
                In Person
                <CheckboxInput
                  aria-label="inPerson"
                  name="inPerson"
                  onChange={handleFormatOfDeliveryChange}
                  type="checkbox"
                  required={
                    formatOfDeliverySelectionIsRequired &&
                    formatOfDelivery.inPerson === 'No' &&
                    formatOfDelivery.online === 'No'
                  }
                  checked={
                    formatOfDeliverySelectionIsRequired && formatOfDelivery.inPerson === 'Yes'
                  }
                  ref={formatOfDeliveryInPersonRef}
                  value={
                    formatOfDeliverySelectionIsRequired
                      ? formatOfDelivery.inPerson === 'Yes'
                        ? 'Yes'
                        : 'No'
                      : activityInfo.formatOfDeliveryAndEventSupport.some((format) =>
                          format.includes('In Person')
                        )
                      ? 'Yes'
                      : 'No'
                  }
                />
                <CustomCheckbox />
              </CheckboxLabel>
              <CheckboxLabel>
                Online
                <CheckboxInput
                  aria-label="online"
                  name="online"
                  onChange={handleFormatOfDeliveryChange}
                  type="checkbox"
                  required={
                    formatOfDeliverySelectionIsRequired &&
                    formatOfDelivery.inPerson === 'No' &&
                    formatOfDelivery.online === 'No'
                  }
                  checked={formatOfDeliverySelectionIsRequired && formatOfDelivery.online === 'Yes'}
                  ref={formatOfDeliveryOnlineRef}
                  value={
                    formatOfDeliverySelectionIsRequired
                      ? formatOfDelivery.online === 'Yes'
                        ? 'Yes'
                        : 'No'
                      : activityInfo.formatOfDeliveryAndEventSupport.some((format) =>
                          format.includes('Virtual')
                        )
                      ? 'Yes'
                      : 'No'
                  }
                />
                <CustomCheckbox />
              </CheckboxLabel>
            </FormatOfDelivery>
          </div>
          <Label
            style={{
              position: 'relative',
              marginTop: formatOfDeliverySelectionIsRequired ? '1.8rem' : '1.45rem'
            }}>
            Preferred Date(s), if known:
            <div style={{ position: 'relative' }}>
              <Input
                aria-label="Enter your preferred date, if known."
                name="preferredDate"
                onChange={(e) => {
                  if (calendarIsOpen) setCalendarIsOpen(false);
                  handleChange(e);
                }}
                onBlur={() => !userIsInteractingWithCalendar && setCalendarIsOpen(false)}
                type="text"
                value={preferredDate}
                ref={preferredDateRef}
                // placeholder="DD/MM/YYYY"
                // pattern={preferredDateRegex}
                // required
              />
              <StyledCalendarIcon
                fontSize="1.375rem"
                onClick={() => {
                  if (calendarValue) setCalendarValue(null);
                  setCalendarIsOpen(true);
                }}
              />
            </div>
            <AnimatePresence>
              {calendarIsOpen && (
                <CalendarWrapper
                  onMouseEnter={() => setUserIsInteractingWithCalendar(true)}
                  onMouseLeave={() => setUserIsInteractingWithCalendar(false)}
                  onBlur={() => !userIsInteractingWithCalendar && setCalendarIsOpen(false)}>
                  <Calendar
                    onChange={setCalendarValue}
                    value={calendarValue}
                    minDate={new Date()}
                    formatShortWeekday={(locale, date) => format(date, 'ccccc')}
                    prev2Label={null}
                    next2Label={null}
                    onClickDay={() => setCalendarIsOpen(false)}
                  />
                </CalendarWrapper>
              )}
            </AnimatePresence>
          </Label>
          <Label>
            Message<Asterisks>*</Asterisks>
            <MessageInput
              aria-label="Enter your message"
              as="textarea"
              cols="30"
              name="message"
              onChange={handleChange}
              placeholder="Please include as much detail as possible, i.e. venue, location, reason for event, etc."
              required
              rows="10"
              value={message}
            />
          </Label>
          <div style={{ position: 'relative', marginTop: onMobile ? '2.15rem' : '1.325rem' }}>
            <CheckboxParentPseudoLabel>
              Would you like to be added to our mailing list?
            </CheckboxParentPseudoLabel>
            <CheckboxLabel>
              <span style={{ position: 'relative', bottom: '2px' }}>Yes</span>
              <CheckboxInput
                aria-label="Would you like to be added to our mailing list"
                name="addToMailingList"
                onChange={handleChange}
                type="checkbox"
                checked={addToMailingList === 'Yes'}
                value={addToMailingList === 'Yes' ? 'Yes' : 'No'}
              />
              <CustomCheckbox />
            </CheckboxLabel>
          </div>
          <AnimatePresence>
            {numberOfParticipants == 201 && (
              <DropsUsALine>
                Please drop us a line on <strong>{`+353 1 554 5784`} (Dublin)</strong> or{' '}
                <strong>+442896205700 (Belfast)</strong> so we can provide you a quote for an
                event of this size. We'll be happy to help!
              </DropsUsALine>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {numberOfParticipants < 201 && (
              <GenerateQuote
                type="submit"
                width="153px"
                height="40px"
                ariaLabel="submit form"
                fontColor="--dynamic-dark-grey"
                buttonColor="--dynamic-yellow"
                buttonHoverColor="--dynamic-blue"
                buttonPressedColor="--dynamic-purple"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)">
                {generatingQuoteStatus.sending ? (
                  <LoadingSpinner style={{ width: '1.625rem', color: '#fff' }} />
                ) : (
                  'Generate Quote'
                )}
              </GenerateQuote>
            )}
          </AnimatePresence>
        </section>
      </Form>
    </Modal>
  );
});

const Modal = styled(motion.dialog).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})`
  background: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 3px 3px 7px rgba(189, 189, 189, 0.4);
  color: #000;
  padding: 2.813rem 1rem 3.5rem;

  @media screen and (min-width: 768px) {
    padding: 2.813rem 3.313rem 5.55rem;
  }

  &::backdrop {
    background-color: rgb(0 0 0 / 0.25);
    backdrop-filter: blur(2px);
  }

  h2 {
    color: var(--dynamic-dark-grey);
    font-family: adelle, serif;
    font-size: 1.313rem;
    font-weight: 700;
    margin-bottom: 3px;
  }

  h3 {
    color: var(--dynamic-blue);
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`;

const GenerateQuote = styled(Button)``;

const DropsUsALine = styled(motion.p).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})`
  color: var(--dynamic-dark-blue);
  display: inline;
  max-width: 21em;
  text-align: center;
  font-size: 1rem;
  line-height: 1.25em;

  @media screen and (min-width: 768px) {
    text-align: right;
  }
`;

const Form = styled.form`
  display: grid;
  grid-column-gap: 1.25rem;
  position: relative;

  section {
    display: flex;
    flex-direction: column;

    &:nth-of-type(2) {
      ${GenerateQuote},
      ${DropsUsALine} {
        margin-top: 2.8rem;
        align-self: center;
      }
    }
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;

    section {
      &:nth-of-type(2) {
        grid-column: 2;

        ${GenerateQuote},
        ${DropsUsALine} {
          align-self: flex-end;
          bottom: -4.5rem;
          position: absolute;
          right: 0;
        }

        ${GenerateQuote} {
          bottom: -2.5rem;
        }

        ${DropsUsALine} {
          bottom: -4.75rem;
        }
      }
    }
  }
`;

const labelStyles = css`
  color: var(--dynamic-dark-grey);
  display: inline-block;
  font-size: 0.875rem;
  font-family: 'proxima-nova', sans-serif;
  letter-spacing: 0.01em;
  line-height: 1.375em;
  margin-top: 1.625em;
  width: 100%;
`;

const Label = styled.label`
  ${labelStyles};
`;

const Asterisks = styled.span`
  color: var(--dynamic-red);
  margin-left: 3px;
`;

const Input = styled.input`
  background-color: #f0f0f0;
  border-radius: 2px;
  border: 1px solid #fff;
  color: var(--dynamic-dark-grey);
  font-size: 0.925rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  margin-top: 4px;
  outline-color: var(--dynamic-blue);
  padding: 0.75em;
  width: 100%;

  &::placeholder {
    color: #bdbdbd;
    font-style: italic;
    font-family: 'proxima-nova', sans-serif;
  }
`;

const MessageInput = styled(Input)`
  height: 128px;
  resize: none;
  width: 100%;
`;

const CustomCheckbox = styled.span`
  background-color: #eee;
  border-radius: 2px;
  height: 25px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 25px;

  &:after {
    border-style: solid;
    border-width: 0 3px 3px 0;
    color: #fff;
    content: '';
    display: none;
    height: 12.5px;
    left: 9px;
    position: absolute;
    top: 5px;
    transform: rotate(45deg);
    width: 7px;
  }
`;

const CheckboxParentPseudoLabel = styled.p`
  position: absolute;
  top: -1.6rem;
  ${labelStyles};
`;

const CheckboxLabel = styled(Label)`
  align-self: flex-start;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875em;
  letter-spacing: 0.02em;
  padding: 0.286em 0.571em 0.286em 2.429em;
  position: relative;
  user-select: none;
  width: auto;

  &:hover ${CustomCheckbox} {
    background-color: #ccc;
  }

  input:checked ~ ${CustomCheckbox} {
    background-color: var(--dynamic-blue);
  }

  input:checked ~ ${CustomCheckbox}:after {
    display: block;
  }
`;

const CheckboxInput = styled.input`
  cursor: pointer;
  height: 1px;
  left: 6.5rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: -0.125rem;
  width: 1px;
`;

const Participants = styled.div`
  font-family: 'proxima-nova', sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.375em;
  margin-top: 0.625em;
  position: relative;
  width: unset;

  label {
    margin-top: 0.85em;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
      margin-top: 0.2em;
    }
  }

  span {
    bottom: -0.325em;
    pointer-events: none;
    position: absolute;

    &:nth-of-type(1) {
      left: 0;
    }

    &:nth-of-type(2) {
      right: 0;
    }
  }
`;

const ParticipantsInput = styled(Input)`
  font-weight: 700;
  margin-left: 7px;
  padding: 0.475em 0.75em;
  width: ${({ numberOfParticipants }) =>
    numberOfParticipants.toString().length < 3 ? '58px' : '66px'};

  &::placeholder {
    font-style: normal;
    font-weight: 700;
  }

  // Always show the up/down controls.
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    left: 4px;
    opacity: 1;
    position: relative;
  }
`;

const ParticipantsRangeInput = styled(Input)`
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  display: block;
  margin: 1rem 0 0;
  padding: 0;
  width: 100%;

  &::-webkit-slider-runnable-track {
    appearance: none;
    background-color: #f0f0f0;
    border-radius: 1.25rem;
    height: 0.25rem;
    outline: none;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background-color: var(--dynamic-blue);
    border-radius: 50%;
    box-shadow: 0.125rem 0.125rem 0.125rem rgba(000, 000, 000, 0.3),
      0rem 0rem 0.125rem rgba(000, 000, 000, 0.1);
    height: 0.625rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 0.625rem;
  }
`;

const CustomRangeTrack = styled.div`
  background-color: var(--dynamic-blue);
  border-radius: 1.25rem;
  height: 0.25rem;
  left: 0;
  position: absolute;
  top: 0.063rem;
  width: calc(${({ numberOfParticipants }) => `${numberOfParticipants / 2}%`} - 0.156rem);
`;

const FormatOfDelivery = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.35rem;
  white-space: nowrap;
`;

function CalendarIcon(props) {
  return (
    <motion.svg width="1em" height="1em" viewBox="0 0 23 24" fill="none" {...props}>
      <path
        d="M6.727 10.479h-1.67a.481.481 0 00-.478.484v1.696c0 .268.213.485.477.485h1.671a.481.481 0 00.478-.485v-1.696a.481.481 0 00-.478-.484zM10.666 10.479H8.995a.481.481 0 00-.478.484v1.696c0 .268.214.485.478.485h1.671a.481.481 0 00.477-.485v-1.696a.481.481 0 00-.477-.484zM14.605 10.479h-1.671a.481.481 0 00-.477.484v1.696c0 .268.213.485.477.485h1.671a.481.481 0 00.478-.485v-1.696a.481.481 0 00-.478-.484zM18.544 10.479h-1.671a.481.481 0 00-.478.484v1.696c0 .268.214.485.478.485h1.671a.481.481 0 00.477-.485v-1.696a.481.481 0 00-.477-.484zM6.727 14.476h-1.67a.481.481 0 00-.478.485v1.696c0 .268.213.485.477.485h1.671a.481.481 0 00.478-.485v-1.696a.481.481 0 00-.478-.485zM14.605 14.476h-1.671a.481.481 0 00-.477.485v1.696c0 .268.213.485.477.485h1.671a.481.481 0 00.478-.485v-1.696a.481.481 0 00-.478-.485zM18.544 14.476h-1.671a.481.481 0 00-.478.485v1.696c0 .268.214.485.478.485h1.671a.481.481 0 00.477-.485v-1.696a.481.481 0 00-.477-.485zM6.727 18.608h-1.67a.481.481 0 00-.478.484v1.696c0 .268.213.485.477.485h1.671a.481.481 0 00.478-.485v-1.696a.481.481 0 00-.478-.484zM10.666 18.608H8.995a.481.481 0 00-.478.484v1.696c0 .268.214.485.478.485h1.671a.481.481 0 00.477-.485v-1.696a.481.481 0 00-.477-.484zM14.605 18.608h-1.671a.481.481 0 00-.477.484v1.696c0 .268.213.485.477.485h1.671a.481.481 0 00.478-.485v-1.696a.481.481 0 00-.478-.484zM10.666 14.46H8.995a.481.481 0 00-.478.485v1.696c0 .267.214.484.478.484h1.671a.481.481 0 00.477-.484v-1.696a.481.481 0 00-.477-.485z"
        fill="#4F4F4F"
      />
      <path
        d="M21.127 2.644h-3.146V.874A.868.868 0 0017.119 0a.868.868 0 00-.862.875v1.77H6.943V.874A.868.868 0 006.081 0a.868.868 0 00-.861.875v1.77H2.473C1.549 2.644.8 3.404.8 4.341v17.96C.8 23.24 1.549 24 2.473 24h18.654c.924 0 1.673-.76 1.673-1.698V4.342c0-.938-.749-1.698-1.673-1.698zm-4.87 1.153V4.84c0 .483.386.875.862.875a.868.868 0 00.862-.875V3.797c.288.249.472.619.472 1.033 0 .747-.598 1.353-1.334 1.353a1.344 1.344 0 01-1.334-1.353c0-.414.184-.785.472-1.033zm-11.037 0V4.84c0 .483.385.875.861.875a.868.868 0 00.862-.875V3.797c.289.249.472.619.472 1.033 0 .747-.597 1.353-1.334 1.353A1.344 1.344 0 014.748 4.83c0-.414.183-.785.472-1.033zm16.266 17.977c0 .535-.427.969-.955.969H3.268a1.162 1.162 0 01-1.154-1.171V9.008h19.372v12.766z"
        fill="#4F4F4F"
      />
    </motion.svg>
  );
}

const StyledCalendarIcon = styled(CalendarIcon).attrs({
  whileTap: { scale: 0.9 }
})`
  cursor: pointer;
  position: absolute;
  right: 0.591em;
  top: 0.545em;
`;

const CalendarWrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  left: calc(50% - 133px);
  position: absolute;
  top: -1.525rem;
  z-index: 3;
`;

export default GetQuoteModal;
