const React = require('react');
const ReactDOM = require('react-dom/client');
const Layout = require('./src/components/Shared/Layout').default;

exports.replaceHydrateFunction = () => (element, container) => {
  const root = ReactDOM.createRoot(container);
  root.render(element);
};

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
