import * as React from 'react';
function SvgNumbers(props) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5 32.3v-6c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5v6M20 17.7a5 5 0 100-10 5 5 0 000 10z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M5 29.3v-3.6c0-2.8 2.2-5 5-5 1.4 0 2.6.6 3.5 1.5M10 19.3a3 3 0 100-6 3 3 0 000 6zM35 29.3v-3.6c0-2.8-2.2-5-5-5-1.4 0-2.6.5-3.5 1.4M30 19.3a3 3 0 100-6 3 3 0 000 6z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgNumbers;
