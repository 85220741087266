import * as React from 'react';
function SvgCloseHamburgerMenuIcon(props) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.929 27.071l14.142-14.142M12.929 12.929L27.071 27.07"
        stroke="#fff"
        strokeWidth={3}
      />
    </svg>
  );
}
export default SvgCloseHamburgerMenuIcon;
