import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { QuestionMark } from 'images/shared/misc';

const Tooltip = ({ title, text, currentlyOpenTooltip, setCurrentlyOpenTooltip, setIsMouseOverAnyTooltip }) => {
  const openToolTipPopup = (title) => {
    setCurrentlyOpenTooltip(title);
    setIsMouseOverAnyTooltip(true);
  };

  const closeTooltipPopup = () => {
    setCurrentlyOpenTooltip('');
    setIsMouseOverAnyTooltip(false);
  };

  return (
    <Wrapper
      onClick={(e) => {
        e.preventDefault();
        closeTooltipPopup();
      }}
      onHoverStart={() => openToolTipPopup(title)}
      onHoverEnd={closeTooltipPopup}
      onTapStart={() => openToolTipPopup(title)}
      onTapCancel={closeTooltipPopup}>
      <QuestionMark />
      <AnimatePresence>
        {currentlyOpenTooltip === title && (
          <TooltipPopup onClick={closeTooltipPopup}>
            <div>
              <Triangle />
              <h3>{title}</h3>
              <p>{text}</p>
            </div>
          </TooltipPopup>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled(motion.span)`
  align-items: center;
  background-color: var(--dynamic-blue);
  border-radius: 50%;
  bottom: 0.063rem;
  display: inline-flex;
  height: 0.875rem;
  justify-content: center;
  margin: 0.188rem 0 0.188rem 0.563rem;
  position: relative;
  width: 0.875rem;
`;

const TooltipPopup = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})`
  background-color: #f0f0f0;
  border-radius: 0.625rem;
  box-shadow: 0.188rem 0.188rem 0.438rem rgba(189, 189, 189, 0.4);
  position: absolute;
  top: 1.9rem;
  white-space: normal;
  width: 20rem;
  z-index: 1;

  > div {
    padding: 1.95rem 1.325rem 1.5rem;
    position: relative;

    h3 {
      font-size: 1.313rem;
      margin-bottom: 0.762em !important;
    }

    p {
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 54.375rem) {
    left: 1.8rem;
    top: -2rem;
    width: 25rem;
  }
`;

const Triangle = styled.span`
  background-color: #f0f0f0;
  height: 0.875rem;
  left: 47.75%;
  pointer-events: none;
  position: absolute;
  top: -0.438rem;
  transform: rotate(45deg);
  width: 0.875rem;

  @media screen and (min-width: 54.375rem) {
    left: -0.438rem;
    top: 2rem;
  }
`;

export default Tooltip;
