import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import { StaticQuery, graphql } from 'gatsby';
import { Cards } from 'components';
import styled, { css } from 'styled-components';
import { remark } from 'remark';
import remarkHTML from 'remark-html';
import { Markup } from 'interweave';
import CardsIntro from './CardsIntro';
import { sortByGridOrder } from 'utils';
import { InView } from 'react-intersection-observer';
import BlobPurpleBlueSVG from 'images/event_management/blob_purple_blue.svg';
import BlobRedBlueSVG from 'images/event_management/blob_red_blue.svg';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_background_3.svg';
import LightPurpleBackgroundSVG from 'images/shared/misc/blobs/light_purple_background.svg';

const toHTML = (value) => remark().use(remarkHTML).processSync(value).toString();

class Services extends PureComponent {
  state = {
    animateLightBlueBackgroundSVG: false,
    animateLightPurpleBackgroundSVGL: false
  };

  handleBlueBackgroundSVG = () => this.setState({ animateLightBlueBackgroundSVG: true });

  handlePurpleBackgroundSVG = () => this.setState({ animateLightPurpleBackgroundSVG: true });

  render() {
    const { viewportWidth, pathname, cardsIntro, blurb } = this.props;
    const { animateLightBlueBackgroundSVG, animateLightPurpleBackgroundSVG } = this.state;
    return (
      <StaticQuery
        query={graphql`
          {
            eventManagementServices: allFile(
              filter: {
                sourceInstanceName: { eq: "cms_data_event_management_services" }
                ext: { eq: ".md" }
              }
              sort: { childMarkdownRemark: { frontmatter: { title: ASC } } }
            ) {
              edges {
                node {
                  id
                  childMarkdownRemark {
                    frontmatter {
                      title
                      summary
                      featuredImg {
                        alt
                        src {
                          childImageSharp {
                            gatsbyImageData(
                              width: 350
                              height: 200
                              transformOptions: { cropFocus: CENTER }
                              quality: 35
                              pngOptions: { compressionSpeed: 10 }
                              placeholder: TRACED_SVG
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            gridOrder: file(sourceInstanceName: { eq: "cms_data_settings_grid_orders" }) {
              childMarkdownRemark {
                frontmatter {
                  eventManagementServices {
                    title
                  }
                }
              }
            }
          }
        `}
        render={({ eventManagementServices, gridOrder }) => {
          const showTheseCards = gridOrder
            ? sortByGridOrder(eventManagementServices, gridOrder)
            : eventManagementServices.edges;
          return (
            <Wrapper>
              <InView onChange={this.handleBlueBackgroundSVG} triggerOnce>
                <CardsIntro cardsIntro={cardsIntro} toHTML={toHTML} />
              </InView>
              {eventManagementServices && (
                <Cards showTheseCards={showTheseCards} pathname={pathname} />
              )}
              <LightBlueBackground
                animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
                src={LightBlueBackgroundSVG}
                alt="light blue background"
                animationDuration="0.75s"
              />
              <InView onChange={this.handlePurpleBackgroundSVG} triggerOnce>
                <DetailedOverview>
                  {viewportWidth > 900 && (
                    <>
                      <BlobPurpleBlue src={BlobPurpleBlueSVG} alt="purple and blue blob" />
                      <BlobRedBlue src={BlobRedBlueSVG} alt="red and blue blob" />
                    </>
                  )}
                  <Markup content={toHTML(blurb)} noWrap />
                </DetailedOverview>
              </InView>
              <LightPurpleBackground
                src={LightPurpleBackgroundSVG}
                alt="light purple background"
                animateLightPurpleBackgroundSVG={animateLightPurpleBackgroundSVG}
                animationDuration="0.75s"
              />
            </Wrapper>
          );
        }}
      />
    );
  }
}

const Wrapper = styled.section`
  margin-bottom: 0;
  padding: 5% 6.181% 0;
  position: relative;

  @media screen and (min-width: 1440px) {
    padding: 80px 0 0;
  }
`;

const DetailedOverview = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 7px 7px 16px rgba(189, 189, 189, 0.4);
  margin: 4.5em auto 0;
  max-width: 775px;
  padding: 2.5rem 1rem 0.5rem;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 500px) {
    padding: 3rem 3rem 1.25rem;
  }

  p, ul, ol {
    color: var(--dynamic-dark-grey);
    font-family: 'proxima-nova', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;
    margin-bottom: 2.125em;

    @media screen and (min-width: 1200px) {
      font-size: 1.2rem;
    }
  }

  li {
    font-size: 1.1rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;

    @media screen and (min-width: 1200px) {
      font-size: 1.2rem;
    }
  }

  a {
    color: var(--dynamic-dark-blue);
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.75rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: adelle, serif;
    margin: -0.075em 0 0.25em;
  }

  ul,
  ol {
    margin-bottom: 2.125em;
    margin-left: 2rem;
    margin-top: 0;
    padding-left: 0;
  }
`;

const BlobRedBlue = styled.img`
  position: absolute;
  right: 100%;
  bottom: -6px;
`;

const BlobPurpleBlue = styled.img`
  left: 100%;
  position: absolute;
  top: -53.5px;
`;

const LightBlueBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  top: 230px;
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

const LightPurpleBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  bottom: -760px;
  transform: translate3d(100%, 0, 0);
  width: 2560px;
  opacity: 0.425;

  ${({ animateLightPurpleBackgroundSVG, animationDuration }) =>
    animateLightPurpleBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Services);
