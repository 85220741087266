import * as React from 'react';
function SvgApproach(props) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28 17.6c0-4.7-4.1-8.5-8.9-7.9-3.6.4-6.5 3.3-7 6.9-.3 2.5.5 4.7 2 6.4 1.8 2 3 4.5 3 7.2v.8h6v-.6c0-2.7.9-5.3 2.8-7.2 1.2-1.5 2.1-3.4 2.1-5.6zM20 31V19.8M17 33.3c0 1.7 1.3 3 3 3s3-1.3 3-3M26.9 9.3l2-2M20 6.5V3.7M11.1 7.3l2 2M33.6 16.2h-2.8M9.2 16.2H6.4M17 19.8h6"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgApproach;
