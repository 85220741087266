import * as React from 'react';
function SvgHamburgerMenuIcon(props) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={40} height={40} rx={10} fill="#4DB1E8" />
      <path d="M10 28h20M10 20h20M10 12h20" stroke="#fff" strokeWidth={3} />
    </svg>
  );
}
export default SvgHamburgerMenuIcon;
