function getExcerpt(text, charLimit) {
  let indexToBreakTextAt;

  for (let j = charLimit; j >= 0; j--) {
    if (text.charAt(j) === ' ') {
      indexToBreakTextAt = j;
      break;
    }
  }

  const splitSummary = text.split('');
  splitSummary.splice(indexToBreakTextAt, 1, '...');
  const excerpt = splitSummary.slice(0, indexToBreakTextAt + 1).join('');

  return excerpt;
}

export default getExcerpt;
