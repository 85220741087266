import { scroller } from 'react-scroll';

const scrollToAnchor = (anchor, { offset = 0, duration = 1000, delay = 0 } = {}) => {
  scroller.scrollTo(anchor, {
    offset,
    duration,
    delay,
    smooth: 'easeInOutQuint',
    ignoreCancelEvents: true
  });
};

export default scrollToAnchor;
