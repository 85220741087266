import * as React from 'react';
function SvgPhoneIcon(props) {
  return (
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.973 9.299c-.033-.102-.25-.254-.648-.451-.107-.063-.262-.147-.46-.257-.2-.107-.378-.209-.54-.298-.16-.09-.313-.18-.456-.263l-.212-.15a2.234 2.234 0 00-.302-.181.576.576 0 00-.242-.06c-.113 0-.256.08-.426.242-.17.16-.326.337-.47.528a5.236 5.236 0 01-.45.528c-.158.162-.29.242-.39.242a.6.6 0 01-.192-.042 4.835 4.835 0 01-.176-.071 3.747 3.747 0 01-.203-.12 4.442 4.442 0 00-.161-.098 8.953 8.953 0 01-2.003-1.484 9.045 9.045 0 01-1.484-2.003A4.442 4.442 0 003.06 5.2a2.728 2.728 0 01-.12-.206 1.296 1.296 0 01-.071-.176.616.616 0 01-.042-.191c0-.102.08-.233.242-.391.16-.158.337-.31.528-.45.191-.144.367-.3.528-.47.162-.17.242-.313.242-.426 0-.081-.02-.162-.06-.242a2.37 2.37 0 00-.182-.302c-.083-.12-.131-.19-.149-.212a11.807 11.807 0 01-.263-.456l-.298-.54c-.108-.2-.194-.353-.257-.46C2.955.275 2.803.06 2.701.027A.45.45 0 002.522 0a2.66 2.66 0 00-.6.087 3.396 3.396 0 00-.585.179C1.054.386.752.73.433 1.304.146 1.84 0 2.367 0 2.89a3.163 3.163 0 00.137.937c.051.182.093.316.123.406.03.086.09.245.176.471.086.227.137.368.152.418.2.559.436 1.054.708 1.493.447.728 1.06 1.478 1.835 2.254.776.776 1.529 1.388 2.254 1.835.439.272.934.508 1.493.708l.418.152c.226.087.385.143.471.176.087.03.224.072.406.123A3.163 3.163 0 009.11 12c.523 0 1.051-.146 1.585-.436.574-.32.92-.618 1.04-.904.062-.144.122-.338.178-.585.057-.248.087-.448.087-.6a.444.444 0 00-.027-.176z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgPhoneIcon;
