import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import { Button } from 'components';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import SmallRightArrow from 'images/shared/misc/SmallRightArrow';
import transition from 'styled-transition-group';
import RedHumpSVG from 'images/home/choose_your_experience/red_hump.svg';
import BlueBlobSVG from 'images/home/choose_your_experience/blue_blob.svg';
import GreyBlobSVG from 'images/home/choose_your_experience/grey_blob.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

class ChooseYourExperience extends PureComponent {
  state = {
    hoveredService: ''
  };

  services = [
    {
      title: 'Team Building',
      ariaLabel: 'team building',
      altText: 'Team cheering',
      link: '/team-building',
      color: '#4DB1E8'
    },
    {
      title: 'Team Training',
      ariaLabel: 'team training',
      altText: 'Person placing sticky notes on glass while team watches',
      link: '/team-training',
      color: '#ED5145'
    },
    {
      title: 'Event Management',
      ariaLabel: 'event management',
      altText: 'People seated and watching an event',
      link: '/event-management',
      color: '#712ca5'
    }
  ];

  render() {
    const { viewportWidth } = this.props;
    const { hoveredService } = this.state;
    return (
      <StaticQuery
        query={graphql`
          {
            youngPeopleHappyAndCheering: file(
              relativePath: {
                eq: "home/choose_your_experience/young_people_happy_and_cheering.jpg"
              }
            ) {
              name
              childImageSharp {
                gatsbyImageData(
                  width: 340
                  height: 340
                  quality: 50
                  pngOptions: { compressionSpeed: 10 }
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
            peopleSittingInCinema: file(
              relativePath: { eq: "home/choose_your_experience/people_sitting_in_cinema.jpg" }
            ) {
              name
              childImageSharp {
                gatsbyImageData(
                  width: 340
                  height: 340
                  quality: 50
                  pngOptions: { compressionSpeed: 10 }
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
            peopleTeamTraining: file(
              relativePath: { eq: "home/choose_your_experience/team_training.jpg" }
            ) {
              name
              childImageSharp {
                gatsbyImageData(
                  width: 340
                  height: 340
                  quality: 50
                  pngOptions: { compressionSpeed: 10 }
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        `}
        render={({ youngPeopleHappyAndCheering, peopleSittingInCinema, peopleTeamTraining }) => {
          return (
            <Wrapper>
              {viewportWidth > 935 && (
                <>
                  <BlueBlob
                    in={hoveredService === 'team building'}
                    src={BlueBlobSVG}
                    alt="blue blob"
                    timeout={200}
                    unmountOnExit
                  />
                  <GreyBlob
                    src={GreyBlobSVG}
                    alt="grey blob"
                    in={hoveredService === 'event management'}
                    timeout={200}
                    unmountOnExit
                  />
                  <Hump
                    src={RedHumpSVG}
                    alt="red hump"
                    in={hoveredService === 'team training'}
                    timeout={200}
                    unmountOnExit
                  />
                </>
              )}
              <InnerWrapper>
                <h2>Choose Your Experience</h2>
                <p>
                  Every client&apos;s wishes are different. From breakthrough online and in-person
                  team building activities for employees to challenge performance, fun filled
                  company celebrations that are about nothing other than capturing the moment, to
                  customised brand launch events that thrill your customers.
                  <br />
                  <br />
                  Choose the idea that suits you.
                </p>
                <ServiceWrapper>
                  {this.services.map((service, index) => {
                    const image =
                      index === 0
                        ? youngPeopleHappyAndCheering
                        : index === 1
                        ? peopleTeamTraining
                        : peopleSittingInCinema;
                    return (
                      <Service
                        hovered={hoveredService === service.ariaLabel || hoveredService === ''}
                        color={service.color}
                        onMouseEnter={() =>
                          this.setState({
                            hoveredService: service.ariaLabel
                          })
                        }
                        onMouseLeave={() =>
                          this.setState({
                            hoveredService: ''
                          })
                        }>
                        <Link to={service.link}>
                          <ServiceImg image={getImage(image)} />
                          {viewportWidth < 600 ? (
                            <Button
                              text={service.title}
                              ariaLabel={service.ariaLabel}
                              width="240px">
                              <StyledSmallRightArrow />
                            </Button>
                          ) : (
                            <ServiceName color={service.color}>{service.title}</ServiceName>
                          )}
                        </Link>
                      </Service>
                    );
                  })}
                </ServiceWrapper>
              </InnerWrapper>
            </Wrapper>
          );
        }}
      />
    );
  }
}

const StyledSmallRightArrow = styled(SmallRightArrow)`
  margin-left: 5px;

  path {
    stroke: #fff;
  }
`;

const Wrapper = styled.div`
  background-color: var(--dynamic-blue);
  position: relative;
  padding: 3.2%;

  @media screen and (min-width: 600px) {
    background-color: var(--site-background);
    padding: 2.22% 0;
  }
`;

const InnerWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 7px 7px 16px rgba(189, 189, 189, 0.4);
  margin: 0 auto;
  padding: 5% 2.5% 0%;
  position: relative;
  max-width: 1264px;
  z-index: 2;

  @media screen and (min-width: 600px) {
    padding: 5% 5% 7.5%;
    width: 95%;
  }

  @media screen and (min-width: 936px) {
    width: 87.75%;
    padding: 51px 80px 68px;
  }

  h2,
  p {
    color: var(--dynamic-dark-grey);
    font-family: 'proxima-nova', sans-serif;
    text-align: center;
  }

  h2 {
    font-size: 6.25vw;
    margin: 0.5em auto 0.8em;

    @media screen and (min-width: 600px) {
      font-size: 3.75rem;
      margin: 0 auto 0.6em;
    }
  }

  p {
    color: rgba(79, 79, 79, 0.9);
    font-size: 1rem;
    line-height: 1.5em;
    margin: 0 auto 1.225em;
    width: 95%;

    @media screen and (min-width: 600px) {
      font-size: 1.25rem;
      margin: 0 auto 2.45em;
    }

    @media screen and (min-width: 700px) {
      width: 78%;
    }
  }
`;

const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1100px;
  gap: 1rem;
  width: 100%;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    flex-flow: row wrap;
    row-gap: 3rem;
    justify-content: space-between;
  }

  @media screen and (min-width: 868px) {
    flex-flow: unset;
  }

  a {
    text-decoration: none;
  }
`;

const makeGreyScale = keyframes`
  from {
    filter: grayscale(0%);
    opacity: 1;
  }

  to {
    filter: grayscale(100%);
    opacity: 0.65;
  }
`;

const ServiceImg = styled(GatsbyImage)`
  border-radius: 5px;
  box-shadow: 0.438em 0.438em 0.375em rgba(189, 189, 189, 0.3);
  transition: transform 0.3s ease-in-out;
  @media screen and (min-width: 600px) {
    border-radius: 10px;
  }
`;

const Service = styled.div`
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 0 auto;

  @media screen and (min-width: 600px) {
    max-width: 47%;
    width: 100%;
  }

  @media screen and (min-width: 864px) {
    max-width: 49.09%;
  }

  button {
    margin: 0 auto;
    position: relative;
    bottom: 22px;

    span {
      display: flex;
      margin-left: 4px;
      margin-bottom: 1px;
      flex-direction: row-reverse;
    }

    &:hover ${StyledSmallRightArrow} {
      transform: translate3d(5px, 0, 0);
      transition: transform 0.2s ease-in-out;
    }

    &:not(:hover) ${StyledSmallRightArrow} {
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-in-out;
    }
  }

  @media screen and (min-width: 935px) {
    ${({ hovered }) => {
      if (!hovered) {
        return css`
          ${ServiceImg} {
            animation: ${makeGreyScale} 0.2s forwards;
          }
        `;
      }
    }}
  }
`;

const ServiceName = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 32px;
  color: #ffffff;
  font-family: 'proxima-nova', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  padding: 0.75rem 1.25rem;
  margin: 2rem auto 0 auto;
  width: 250px;
`;

const Blob = transition.img`
  height: 100%;
  position: absolute;
  top: 0;

  &:enter { opacity: 0.01; }
  &:enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  &:exit { opacity: 1; }
  &:exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }
`;

const BlueBlob = styled(Blob)`
  left: 0;
`;
const GreyBlob = styled(Blob)`
  right: 0;
  bottom: 0;
`;

const Hump = styled(Blob)`
  width: 100%;
  height: auto;
  top: unset;
  bottom: 0;
  left: 0;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ChooseYourExperience);
