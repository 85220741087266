import React from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';

const SectionTitle = ({ title, paddingStyle }) => (
  <H2 paddingStyle={paddingStyle} dangerouslySetInnerHTML={{ __html: title }} />
);

const H2 = styled.h2`
  color: var(--dynamic-dark-grey);
  font-family: 'proxima-nova', sans-serif;
  font-size: 7.8vw;
  font-style: normal;
  /* Disable this to check original spacing */
  /* line-height: 2.5rem;  */
  font-weight: 400;
  text-align: center;
  ${({ paddingStyle }) =>
    paddingStyle &&
    css`
      padding: ${paddingStyle};
    `};

  @media screen and (min-width: 540px) {
    font-size: 2.625rem;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(SectionTitle);
