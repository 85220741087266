import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { GetButton } from 'components';

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  ({ brochureUrl, info, viewportWidth }) => {
    const { html } = info;
    return (
      <Text>
        {html && (
          <>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            {brochureUrl && (
              <span style={{ margin: '0 1rem 0 0' }}>
                <GetButton
                  buttonLinkPath={brochureUrl}
                  buttonText="Download Brochure"
                  buttonWidth="202px"
                  buttonHeight="44px"
                />
              </span>
            )}
            <GetButton
              buttonLinkPath="#contact-us"
              ariaLabel="get quote now"
              buttonText="Contact Us Today"
              buttonWidth="202px"
              buttonHeight="44px"
              viewportWidth={viewportWidth}
            />
          </>
        )}
      </Text>
    );
  }
);

/* TODO: Refactor with Activity Info Text */
const Text = styled.div`
  p {
    font-size: 1rem;
    line-height: 1.313em;
    margin-bottom: 2.125em;
    color: var(--dynamic-dark-grey);
  }

  a {
    color: var(--dynamic-dark-blue);
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: adelle, serif;
    margin: -0.075em 0 0.25em;
  }

  > ul {
    list-style: none;
    margin: 0;
  }

  ul {
    li {
      padding-bottom: 1rem;
    }
  }

  ol {
    li {
      padding-bottom: 1rem;
    }
  }

  > div p {
    margin-bottom: 1em;
    line-height: 1.313em;
  }

  ul {
    margin-left: 1rem;
    margin-bottom: 1em;
  }

  > div,
  > ul {
    margin-bottom: 3.4em;
    font-family: 'proxima-nova', sans-serif;
  }

  figure,
  > span.gatsby-resp-image-wrapper,
  div.video-without-caption {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 0.85rem;
    margin-top: 0.25em;
    color: #3b444b;
    text-align: center;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (min-width: 1100px) {
    > div p {
      width: 96%;
    }
  }
`;
