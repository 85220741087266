import * as React from 'react';
function SvgEventType(props) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M31.1 14.5v-7M31.1 32.5v-12M31.1 20.5a3 3 0 100-6 3 3 0 000 6zM20 20.5v-13M20 32.5v-6M20 26.5a3 3 0 100-6 3 3 0 000 6zM8.9 14.5v-7M8.9 32.5v-12M8.9 20.5a3 3 0 100-6 3 3 0 000 6z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgEventType;
