const sortByGridOrder = (elements, gridOrders) => {
  const elementsGridOrder =
    gridOrders.childMarkdownRemark.frontmatter[
      Object.keys(gridOrders.childMarkdownRemark.frontmatter)[0]
    ];

  const sortedElements = elementsGridOrder
    .map(
      ({ title }) =>
        elements.edges.filter(({ node }) => node.childMarkdownRemark.frontmatter.title === title)[0]
    )
    .filter((card) => card !== undefined && card.node.childMarkdownRemark.frontmatter.title);

  const notSortedElements = elements.edges
    .filter(
      ({ node }) =>
        !elementsGridOrder
          .map(({ title }) => title)
          .includes(node.childMarkdownRemark.frontmatter.title)
    )
    .filter((card) => card !== undefined && card.node.childMarkdownRemark.frontmatter.title);

  return [...sortedElements, ...notSortedElements];
};

export default sortByGridOrder;
