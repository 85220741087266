import React from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';
import { Link } from 'gatsby';
import { Button, MultiCarousel, SectionTitle } from 'components';
import BlobRed from 'images/home/favourite_venues/blob_red.svg';
import { getSlug, getExcerpt } from 'utils';

const PaddedMultiCarousel = ({
  cards,
  pathname,
  viewportWidth,
  paddingStyle,
  title
}) => {
  /* If on a venue page, remove that venue from the 'Other Venues' carousel on that page */
  if (pathname.includes('/venue-partners/')) {
    cards.edges = cards.edges.filter(
      ({ node: venue }) => !pathname.includes(getSlug(venue.childMarkdownRemark.frontmatter.title))
    );
  }
  return (
    <Wrapper paddingStyle={paddingStyle}>
      {!pathname.includes('/venues-partners/') && viewportWidth > 570 && (
        <StyledBlobRed src={BlobRed} alt="red blob" />
      )}
      <InnerContainer>
        <SectionTitle title={title} paddingStyle="0.95em 0" />
        {cards && (
          <MultiCarousel
            leftArrowOffset={
              viewportWidth >= 1258
                ? '-2.1%'
                : viewportWidth >= 972
                ? '-6.75%'
                : viewportWidth >= 690
                ? '-10%'
                : 'calc(50% - 47.5px)'
            }
            rightArrowOffset={
              viewportWidth >= 1258
                ? 'calc(-2.1% + 6px)'
                : viewportWidth >= 972
                ? 'calc(-6.75% + 9px)'
                : viewportWidth >= 690
                ? 'calc(-10% + 8px)'
                : 'calc(50% - 47.5px)'
            }
            responsiveRules={{
              largeDesktop: {
                breakpoint: { max: 10000, min: 1258 },
                items: 4
              },
              desktop: {
                breakpoint: { max: 1257, min: 972 },
                items: 3
              },
              table: {
                breakpoint: { max: 971, min: 690 },
                items: 2
              },
              mobile: {
                breakpoint: { max: 689, min: 0 },
                items: 1
              }
            }}>
            {cards.edges.map(({ node: card }, i) => {
              const { summary } = card.childMarkdownRemark.frontmatter;
              return (
                <StyledLink
                  isMobile={isMobile}
                  key={i}
                  to={
                    pathname === '/'
                      ? `/blog/${getSlug(card.childMarkdownRemark.frontmatter.title)}/`
                      : `/venue-partners/${getSlug(card.childMarkdownRemark.frontmatter.title)}/`
                  }>
                  <CarouselImg
                    image={
                      getImage(card.childMarkdownRemark.frontmatter.featuredImg.src)
                    }
                    alt={card.childMarkdownRemark.frontmatter.featuredImg.alt}
                  />
                  <Overlay isMobile={isMobile}>
                    <h3>
                      {card.childMarkdownRemark.frontmatter.title
                        .split(' ')
                        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
                        .join(' ')}
                    </h3>
                    <p>{summary.length > 180 ? getExcerpt(summary, 180) : summary}</p>
                    <Button text="See More" ariaLabel="see more" flexShrink={0} />
                  </Overlay>
                </StyledLink>
              );
            })}
          </MultiCarousel>
        )}
      </InnerContainer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  padding: ${({ paddingStyle }) => paddingStyle};
  z-index: 1;

  /* @media screen and (min-width: 900px) {
    padding: 170px 3.2% 0;
  } */
`;

const InnerContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 7px 7px 16px rgba(189, 189, 189, 0.3);
  margin: 0 auto;
  padding: 0 6.4% calc(6.4% + 20px);
  width: 100%;

  @media screen and (min-width: 429px) {
    padding: 0 6.4% 4em;
    max-width: 400px;
  }

  @media screen and (min-width: 690px) {
    padding: 0 0 4em;
    max-width: 616px;
  }

  @media screen and (min-width: 972px) {
    max-width: 900px;
  }

  @media screen and (min-width: 1258px) {
    max-width: 1170px;
  }
`;

const Overlay = styled.div`
  background-blend-mode: multiply;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 345px;
  max-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5.5% 5.5% 11%;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      height: 100%;
    `}

  @media screen and (min-width: 690px) {
    padding: 5.5%;
    max-width: 268px;
    max-height: 340px;

    button {
      margin-bottom: 8%;
    }
  }

  h3,
  p {
    color: #fff;
    text-align: center;
    font-family: 'proxima-nova', sans-serif;
  }

  h3 {
    margin-bottom: 1.25em;
  }

  p {
    margin-bottom: 2.25em;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 4% 8%;
      justify-content: center;

      h3 {
        margin: 0.5em auto 0.625em;
      }

      button {
        display: none;
      }

      p {
        margin-bottom: 1.5em;
      }
    `}
`;

const StyledLink = styled(Link)`
  ${({ isMobile }) =>
    isMobile
      ? css`
          ${Overlay} {
            background: rgba(000, 000, 000, 0.6);
            opacity: 1;
            transition: background, opacity;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
          }
        `
      : css`
          &:hover ${Overlay} {
            background: rgba(000, 000, 000, 0.6);
            opacity: 1;
            transition: background, opacity;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
          }

          &:not(:hover) ${Overlay} {
            background: rgba(000, 000, 000, 0);
            opacity: 0;
            transition: background, opacity;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
          }
        `}
`;

const CarouselImg = styled(GatsbyImage)`
  max-width: 345px;
  max-height: 440px;
  background: transparent !important;
  width: 100%;
  @media screen and (min-width: 690px) {
    max-width: 268px;
    max-height: 340px;
  }
`;

const StyledBlobRed = styled.img`
  position: absolute;
  bottom: -100px;
  left: 0;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(PaddedMultiCarousel);
