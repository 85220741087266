import * as React from 'react';
function SvgLinkedinIcon(props) {
  return (
    <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={14} cy={14} r={14} fill="#4F4F4F" />
      <path
        d="M10.504 19.953v-7.906h-2.41v7.906h2.41zm0-10.73C10.504 8.519 10.03 8 9.228 8 8.52 8 8 8.518 8 9.224c0 .705.472 1.223 1.228 1.223.803.047 1.276-.518 1.276-1.223zm4.016 10.73v-4.518c0-.235 0-.47.094-.659.19-.47.614-.988 1.37-.988.992 0 1.56.565 1.56 1.647v4.518H20V15.34c0-2.494-1.323-3.623-3.07-3.623-1.465 0-2.08.8-2.458 1.364v-1.035h-2.409c.047.753 0 7.953 0 7.953h2.457v-.047z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgLinkedinIcon;
