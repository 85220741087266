import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button, SectionTitle } from 'components';
import { InView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_background_2.svg';
import LightPurpleBackgroundSVG from 'images/shared/misc/blobs/light_purple_background.svg';

// We're doing it this way coz unfortunately there's no easy solution to re-ordering things in Netlify CMS - https://github.com/netlify/netlify-cms/issues/475
const instagramLinks = [
  {
    link: 'https://www.instagram.com/p/BJA_tGKg72z/',
    caption: 'Our great event today. #zenfest2016 #summerevent #dynamicevents #weloveevents'
  },
  {
    link: 'https://www.instagram.com/p/BSJrTKGDYZZ/',
    caption:
      'The beep beep jeep is ready to take some of clients for an #offroaddrivingexperience @palmerstownhouseestate #offroad #driving #experience #4x4 #amazing #course #splendid #evening'
  },
  {
    link: 'https://www.instagram.com/p/BSoP7P3j3v_/',
    caption:
      'Fantastic #famtrip @powerscourthotel today! Great to meet with these amazing people! #untilwemeetagain #famtrip #experience #ireland #conferenceandevents #planning #ireland #destination #teamireland #meetinireland #eventmanagement #sunshine'
  },
  {
    link: 'https://www.instagram.com/p/BUWgDM_DMuv/',
    caption:
      'It was great fun working on some brand activation at the #guinnesspro12 match days! #energia #rugby #sponsor #cleverplay #switchedon #officialpartner #untilnexttime'
  },
  {
    link: 'https://www.instagram.com/p/BY5MdpsjmvZ/',
    caption:
      'A nice company #familyday event on Saturday at #FitzwilliamSquare #familyday #family #fun #dublin #bringyourkidstoworkday #summerbbq #company #offsite #eventplanner #eventslife #eventmanagement #event-designers #games #activities #kidsshow #eventprofs #somethingforeveryone'
  },
  {
    link: 'https://www.instagram.com/p/BaOK4VUj_Xu',
    caption:
      "Another week of events complete. Who knows what's next #eventprofs #events #teambuilding #teamevents #fun #BeInspired #playtowin #albufeira #portugal #epicsana #praiadefalesia"
  },
  {
    link: 'https://www.instagram.com/p/BM6rJ8CDXyN/',
    caption:
      'We are ready to go @crokeparkevents for our event today! #events #somethingdifferent #teambuilding #design #create #deliver #dynamicevents #dedicatedeventsteam'
  },
  {
    link: 'https://www.instagram.com/p/CAeyFf_A64Y/',
    caption:
      "🔓 Unlock your team's potential with this fun, team orientated, Virtual Escape Room style game which uses image recognition and augmented reality. A great solution to reignite social connections with your colleagues online. ✨"
  },
  {
    link: 'https://www.instagram.com/p/CE9_R5PgiI7/',
    caption:
      "Tonight's Challengers for the #HodsonBayChallenge. #GameChangingExperiences #OnlineEvents #TeamBuilding #VirtualAwayDay"
  },
  {
    link: 'https://www.instagram.com/p/CBdL0EbHB79/',
    caption:
      'Running some screen tests and rehearsals with Michael Dalton ahead of his #MICDrop webinar on Alternative Revenue Streams for Hotels and Venues which takes place tomorrow at 4pm.'
  },
  {
    link: 'https://www.instagram.com/p/CDiwc7OA6o3/',
    caption:
      "Our custom made solutions are very popular. It allows companies to personalise their online event and is a great way to bring company values to life. Check out our latest creation for Google's recent Virtual Away Day. #GameChangingExperiences #onlineevents"
  },
  {
    link: 'https://www.instagram.com/p/CBQ2S98pTiN/',
    caption: 'Live from Studio 1'
  },
  {
    link: 'https://www.instagram.com/p/CGkIf_MAA32/',
    caption:
      "The Christmas event diary is filling up fast! Don't miss out and book your free demo today where we can guide and advise you on all of the festive options available to you. Stay connected."
  },
  {
    link: 'https://www.instagram.com/p/CHzu5xCgz_m/',
    caption:
      'Big day at Dynamic HQ as we get ready to deliver 16 online events today and our team is getting bigger again! Welcome on board Elle Walsh and Stephen Sheehy.'
  },
  {
    link: 'https://www.instagram.com/p/CJGuK1Pr1Wd/',
    caption: 'Selection of team photos from some of our recent online events. #HappyFaces'
  },
  {
    link: 'https://www.instagram.com/p/CbK0ao0M03z',
    caption:
      'Our first run out of our brand new product - Wind Farm! 💨 This sustainability themed event involves building your own wind propelled lifting apparatus and collecting funds to pay for the parts by completing an app based sustainability themed challenge!'
  },
  {
    link: 'https://www.instagram.com/p/CGDbEu1gwtG',
    caption:
      '✈️ Event crew on standby to take you on a virtual globe trotting trip around the world while putting your teamwork, collaboration and creativity to the test 🌎. #GameChangingExperiences #AroundThe World #Teambuilding #OnlineEvents'
  },
  {
    link: 'https://www.instagram.com/p/CPGGTuGLEDQ',
    caption:
      '🔓 This week we launch the Digital Escape Rooms for Deutsche Telekom Europe. 750 employees in 126 teams from 12 countries will compete over the next 8 weeks...'
  },
  {
    link: 'https://www.instagram.com/p/CahO1Q_saxA',
    caption:
      'Great fun at the @avivastadium in Dublin yesterday doing branding activation with our great friends @energiaireland at the Ireland match. Great activation and a great result! 🏉 #BrandActivation #Dublin #Ireland #EventProfs'
  },
  {
    link: 'https://www.instagram.com/p/CPoJ2tal55p',
    caption:
      "With well over 100 online team building activities scheduled for June what better way to support #pridemonth. We've upgrading the gamescreen on our best selling online team activity for the entire month, The Virtual Away Day. #pride #diversity #inclusion"
  },
  {
    link: 'https://www.instagram.com/p/B1HpIryiDPq/',
    caption:
      'Using our event technology to power a bespoke high octane #SuperCar experience for an incentive event in #Kilkenny with @ovationireland #GameChangingExperiences'
  },
  {
    link: 'https://www.instagram.com/p/Bz7sFvVnpgo/',
    caption:
      'Which way to the tea party? #TeaParty #MadHatter #MarchHare #GameChangingExperiences #DynamicEvents #FamilyFunDay #FunEvents #CorporateEvents #Clonmel'
  },
  {
    link: 'https://www.instagram.com/p/CaUO7k8MRsY',
    caption:
      'With the return of live in person events, Dynamic Events are looking to build a database of event staff that can help us run our events through out the year. Check out the link in our Instagram bio to apply...'
  },
  {
    link: 'https://www.instagram.com/p/Bp5GCJ3HpTv/',
    caption:
      'No matter what accomplishments you make, somebody helped you. #TheBigPicture #WorkingTogether #TeamWork #BeInspired'
  },
  {
    link: 'https://www.instagram.com/p/BqdJknInSE5/',
    caption:
      "We've themed it up tonight with The Great Gatsby at @morganhoteldublin. #BeInspired #PartyNights #Gatsby #EveningEvents #Dublin #TempleBar #GreatGatsby #VintageCar"
  },
  {
    link: 'https://www.instagram.com/p/CaWxzKasGVU',
    caption:
      'An exciting Codebreaker challenge in @CastleknockHotel 🔒 A thrilling race against the clock, there can only be one winner! 🕙 #CodeBreaker #DynamicEvents #TeamBuilding #CastleknockHotel'
  },
  {
    link: 'https://www.instagram.com/p/Cbw5oZ6s7_R',
    caption:
      'Digital Treasure Hunt around the @avivastadium yesterday! #teambuilding #gamechangingexperiences #employeeengagement #eventprofs #inpersonevents #dynamicevents #treasurehunts #havefun #innovatetogether #getcreative #eventprof...'
  },
  {
    link: 'https://www.instagram.com/p/CwfhdUOuZkc/',
    caption:
      'Fun in the sun last Friday as Tritech Group took on the Challenger event at @barberstowncastlehotel'
  },
  {
    link: 'https://www.instagram.com/p/Cy5qvgkuVZO/',
    caption:
      "Amazing results from North Munster Citizens Information Service 'Big Picture' in the @ballykisteenhoteltipperary"
  },
  {
    link: 'https://www.instagram.com/p/Czrhj3msvpz/',
    caption:
      'Another fantastic SolarBuddy: Hour of Power event. This time for Flutter Entertainment in their beautiful Dublin office.'
  },
  {
    link: 'https://www.instagram.com/p/Cq73TjtA1iv/',
    caption:
      'Ready for an unforgettable team-building event? Look no further than our digital treasure hunts! Our bespoke interactive challenges create high-energy, team collaboration, and healthy competition.'
  },
  {
    link: 'https://www.instagram.com/p/CsGwFdIMQ1T/',
    caption:
      "Our Conference & Events team had a fantastic site inspection this morning at a client's brand-new offices!"
  },
  {
    link: 'https://www.instagram.com/p/CyiVTVyOSDf/',
    caption:
      'Our people make us who we are. We are known to be event innovators, perfectionists and fun people to work with, delivering game-changing experiences.'
  },
  {
    link: 'https://www.instagram.com/p/Cwj9upDuLeS/',
    caption:
      "At Dynamic Events Ireland, we're honoured to be the trusted choice of some of the world's most influential brands for crafting unforgettable team-building and event management experiences."
  },
  {
    link: 'https://www.instagram.com/p/Cu4LQfKsYLN/?img_index=1',
    caption:
      'Wow! What an incredible day last Friday at Qualapalooza in @scribblestowngardens 🎵🌞 This music festival-themed summer party for 400 employees from our friends at @qualtrics was an absolute blast! 💃🕺 From mesmerising floating characters to colourful inflatables, DJs spinning groovy beats to mind-blowing live music acts, it was a day filled with unforgettable memories! 🎪🎶'
  },
  {
    link: 'https://www.instagram.com/p/CvPDmIAOcso/?img_index=1',
    caption:
      'Yesterdays "Back to School Sports Day" event held at Pembroke Cricket Club for our friends at PwC was an absolute blast! 🎯 The perfect ingredients of fun, teamwork, and bonding made this event a resounding success! 🙌🌟 It was an extraordinary team-building experience that brought everyone closer together, fostering a strong sense of camaraderie. ⚽️🏅'
  },
  {
    link: 'https://www.instagram.com/p/CyyQ8rTsHHR/?img_index=9',
    caption: 'Engage. Connect. Celebrate. Dynamic Events Ireland – Where Every Event Tells a Story.'
  },
  {
    link: 'https://www.instagram.com/p/CyyQ8rTsHHR/?img_index=2',
    caption: 'Engage. Connect. Celebrate. Dynamic Events Ireland – Where Every Event Tells a Story.'
  },
  {
    link: 'https://www.instagram.com/p/CyyQ8rTsHHR/?img_index=1',
    caption: 'Engage. Connect. Celebrate. Dynamic Events Ireland – Where Every Event Tells a Story.'
  },
  {
    link: 'https://www.instagram.com/p/Cz8-FYmLj2h/?img_index=1',
    caption:
      "A big shout out to our brilliant team involved with the Aircraft Leasing Ireland Global Aviation Sustainability Day. Lauren O'Brien and Niall O'Connor seamlessly managed every detail of the event which was 13 months in the making."
  },
  {
    link: 'https://www.instagram.com/p/C1ZqhmLMHn5/?img_index=1',
    caption:
      'July brought one of our favourite events: @clashofthecompanies 🏆 Hundreds of people from all business sectors came together at the @rdsdublin to compete against each other to raise funds for sick children in Ireland.'
  },
  {
    link: 'https://www.instagram.com/p/C2hZttuOlpP/',
    caption:
      "Benefits of integrating team building activities into your Kick Off event: ◆ Cultivate a Relaxed Atmosphere: Team activities set the stage for a meeting that's not just productive but also relaxed and enjoyable. Break the ice and create an environment where collaboration feels natural."
  },
  {
    link: 'https://www.instagram.com/p/C2zP5PosQWI/?img_index=1',
    caption:
      'This weeks #throwbackthursday is to last weekend, when Niall attended the @wildgooseevents Global Conference in London. This was a meeting of renowned leaders and innovators in the world of team building and event planning from across the globe.'
  }
];

class InstagramFeed extends PureComponent {
  state = {
    animateLightBlueBackgroundSVG: false,
    animateLightPurpleBackgroundSVG: false,
    showAllImgs: false
  };

  removeFileNamePrefix = (fileName) =>
    fileName
      .replace(/[0-9]*_/, '')
      .split('_')
      .join(' ');

  handleShowMoreButton = () => {
    this.handlePurpleBackgroundSVG();
    this.setState({ showAllImgs: true });
  };

  handleBlueBackgroundSVG = (animateLightBlueBackgroundSVG) =>
    this.setState({ animateLightBlueBackgroundSVG });

  handlePurpleBackgroundSVG = () => this.setState({ animateLightPurpleBackgroundSVG: true });

  render() {
    const { viewportWidth } = this.props;
    const { animateLightBlueBackgroundSVG, animateLightPurpleBackgroundSVG, showAllImgs } =
      this.state;
    // const animationDuration = `${viewportWidth / 1920}s`;
    const animationDuration = '0.75s';
    return (
      <Wrapper showAllImgs={showAllImgs}>
        <InView onChange={this.handleBlueBackgroundSVG} threshold={0.99} triggerOnce>
          <SectionTitle title="Instagram Feed" paddingStyle="3.96em 0 0.925em" />
        </InView>
        <InnerWrapper>
          <InstagramImgs showAllImgs={showAllImgs}>
            <StaticQuery
              query={graphql`
                {
                  allInstagramImgs: allFile(
                    filter: { relativeDirectory: { regex: "/home/instagram_feed/instagram_imgs/" } }
                    sort: { name: DESC }
                  ) {
                    edges {
                      node {
                        id
                        name
                        childImageSharp {
                          gatsbyImageData(
                            width: 377
                            height: 377
                            quality: 50
                            pngOptions: { compressionSpeed: 10 }
                            placeholder: TRACED_SVG
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                    }
                  }
                }
              `}
              render={({ allInstagramImgs }) => {
                const firstNineImgs = allInstagramImgs.edges.slice(0, 9);
                const restOfImgs = allInstagramImgs.edges.slice(9);
                const instagramLinksReversedArray = [...instagramLinks].reverse();
                return (
                  <>
                    {firstNineImgs &&
                      firstNineImgs.map(({ node }, i) => (
                        <a
                          key={node.id}
                          target="_blank"
                          href={instagramLinksReversedArray[i].link}>
                          <InstagramImg
                            image={getImage(node)}
                            alt={this.removeFileNamePrefix(node.name)}
                          />
                          <Overlay>
                            <p>{instagramLinksReversedArray[i].caption}</p>
                          </Overlay>
                        </a>
                      ))}
                    {showAllImgs &&
                      restOfImgs &&
                      restOfImgs.map(({ node }, i) => (
                        <a
                          key={node.id}
                          target="_blank"
                          href={instagramLinksReversedArray[i + 9].link}>
                          <InstagramImg
                            image={getImage(node)}
                            alt={this.removeFileNamePrefix(node.name)}
                          />
                          <Overlay>
                            <p>{instagramLinksReversedArray[i + 9].caption}</p>
                          </Overlay>
                        </a>
                      ))}
                  </>
                );
              }}
            />
          </InstagramImgs>
          {!showAllImgs && (
            <div onClick={this.handleShowMoreButton} role="button" tabIndex={0}>
              <Button
                text="Show More"
                ariaLabel="show more"
                width="143px"
                height="40px"
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
              />
            </div>
          )}
        </InnerWrapper>
        <LightBlueBackground
          src={LightBlueBackgroundSVG}
          alt="light blue background"
          animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
          animationDuration={animationDuration}
        />
        <LightPurpleBackground
          src={LightPurpleBackgroundSVG}
          alt="light purple background"
          animateLightPurpleBackgroundSVG={animateLightPurpleBackgroundSVG}
          animationDuration={animationDuration}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.section`
  position: relative;
  margin: 0 auto ${({ showAllImgs }) => (showAllImgs ? '1%' : '21px')};

  @media screen and (min-width: 580px) {
    margin: 4.188em auto ${({ showAllImgs }) => (showAllImgs ? '1%' : '2%')};
  }

  @media screen and (min-width: 1151px) {
    margin: 4.188em auto ${({ showAllImgs }) => (showAllImgs ? '0.05%' : '22px')};
  }
`;

const InnerWrapper = styled.section`
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 3.2%;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 410px) {
    padding: 0;
  }

  @media screen and (min-width: 1144px) {
    margin: 0 auto;
  }

  button {
    margin: 0 auto;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-blend-mode: multiply;
  background-color: rgba(000, 000, 000, 0.575);
  left: 0;
  top: 0;
  position: absolute;
  padding: 5.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #fff;
    line-height: 1.25em;
    text-align: center;
    font-family: 'proxima-nova', sans-serif;
    letter-spacing: 0.02em;
  }
`;

const InstagramImgs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  /* ${({ showAllImgs }) =>
    showAllImgs &&
    css`
      margin-bottom: 5.3em;
    `}; */

  @media screen and (min-width: 580px) {
    flex-direction: row;
  }

  a {
    max-height: 377px;
    max-width: 377px;
    width: 100%;
    position: relative;
    height: 100%;
    margin-bottom: 21px;

    @media screen and (min-width: 580px) {
      margin: 0 1% 2%;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 46%;
    }

    @media screen and (min-width: 820px) {
      max-width: 31%;
    }

    &:hover ${Overlay} {
      opacity: 1;
      transition: opacity 0.375s ease-in-out;
    }

    &:not(:hover) ${Overlay} {
      opacity: 0;
      transition: opacity 0.375s ease-in-out;
    }
  }
`;

const InstagramImg = styled(GatsbyImage)`
  width: 100%;
`;

const LightBlueBackground = styled.img`
  position: absolute;
  top: -69px;
  transform: translate3d(-150%, 0, 0);
  width: 2560px;
  margin-left: 50%;

  ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

const LightPurpleBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  bottom: 0;
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightPurpleBackgroundSVG, animationDuration }) =>
    animateLightPurpleBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(InstagramFeed);
