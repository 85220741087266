import React from 'react';
import sizes from 'react-sizes';
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';
import { Button, MultiCarousel, SectionTitle } from 'components';
import styled, { css } from 'styled-components';
import BlobBlueYellow from 'images/home/new_case_studies/blob_blue_yellow.svg';
import BlobPurpleYellow from 'images/home/new_case_studies/blob_purple_yellow.svg';
import { getSlug, sortByGridOrder } from 'utils';

const NotPaddedMultiCarousel = ({ title, children, cards, gridOrder, viewportWidth }) => {
  const _cards = gridOrder ? sortByGridOrder(cards, gridOrder) : cards.edges;
  return (
    _cards && (
      <Wrapper>
        {viewportWidth > 590 && (
          <StyledBlobBlueYellow src={BlobBlueYellow} alt="blue and yellow blob" />
        )}
        {viewportWidth > 590 && (
          <StyledBlobPurpleYellow src={BlobPurpleYellow} alt="purple and yellow blob" />
        )}
        {children && <div
          style={{
            backgroundColor: '#e6e6e6',
            borderRadius: '10px',
            margin: '79px auto 40px',
            maxWidth: '1252px',
            padding: '2.5rem 1.5rem 2rem',
            width: '87.75%'
          }}>
          {children}
        </div>}
        <SectionTitle title={title} paddingStyle="1.625em 0 1.2em" />
        <MultiCarousel
          leftArrowOffset={
            viewportWidth >= 1258
              ? '-1.1%'
              : viewportWidth >= 972
              ? '-6.75%'
              : viewportWidth >= 690
              ? '-10%'
              : 'calc(50% - 47.5px)'
          }
          rightArrowOffset={
            viewportWidth >= 1258
              ? 'calc(-1.1% + 6px)'
              : viewportWidth >= 972
              ? 'calc(-6.75% + 9px)'
              : viewportWidth >= 690
              ? 'calc(-10% + 8px)'
              : 'calc(50% - 47.5px)'
          }
          responsiveRules={{
            largeDesktop: {
              breakpoint: { max: 10000, min: 1258 },
              items: 4
            },
            desktop: {
              breakpoint: { max: 1257, min: 972 },
              items: 3
            },
            tablet: {
              breakpoint: { max: 971, min: 690 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 689, min: 0 },
              items: 1
            }
          }}>
          {_cards.map(({ node: caseStudy }, i) => {
            const {
              title: caseStudyTitle,
              summary: caseStudySummary,
              featuredImg
            } = caseStudy.childMarkdownRemark.frontmatter;
            return (
              <StyledLink
                isMobile={isMobile}
                key={i}
                to={`/case-studies/${getSlug(caseStudyTitle)}/`}>
                <CarouselImg image={getImage(featuredImg.src)} alt={featuredImg.alt} />
                <Overlay isMobile={isMobile}>
                  <h3>{caseStudyTitle}</h3>
                  <p>{caseStudySummary}</p>
                  <Button text="See More" ariaLabel="see more" />
                </Overlay>
              </StyledLink>
            );
          })}
        </MultiCarousel>
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 0 3.2%;

  @media screen and (min-width: 690px) {
    padding: 0;
  }
`;

const Overlay = styled.div`
  align-items: center;
  background-blend-mode: multiply;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  max-height: 507px;
  max-width: 400px;
  padding: 5.5% 5.5% 11%;
  position: absolute;
  width: 100%;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      height: 100%;
    `}

  @media screen and (min-width: 690px) {
    padding: 5.5%;
    max-width: 268px;
    max-height: 340px;

    button {
      margin-bottom: 8%;
    }
  }

  h3,
  p {
    color: #fff;
    text-align: center;
    font-family: 'proxima-nova', sans-serif;
  }

  h3 {
    margin-bottom: 1.25em;
  }

  p {
    margin-bottom: 2.25em;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 4% 8%;
      justify-content: center;

      h3 {
        margin: 0.5em auto 0.625em;
      }

      button {
        display: none;
      }

      p {
        margin-bottom: 1.5em;
      }
    `}
`;

const StyledLink = styled(Link)`
  ${({ isMobile }) =>
    isMobile
      ? css`
          ${Overlay} {
            background: rgba(000, 000, 000, 0.45);
            opacity: 1;
            transition: background, opacity;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
          }
        `
      : css`
          &:hover ${Overlay} {
            background: rgba(000, 000, 000, 0.45);
            opacity: 1;
            transition: background, opacity;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
          }

          &:not(:hover) ${Overlay} {
            background: rgba(000, 000, 000, 0);
            opacity: 0;
            transition: background, opacity;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
          }
        `}
`;

const CarouselImg = styled(GatsbyImage)`
  background: transparent !important;
  height: 100%;
  max-height: 507px;
  max-width: 400px;
  width: 100%;
  @media screen and (min-width: 690px) {
    max-width: 268px;
    max-height: 340px;
  }
`;

const StyledBlobBlueYellow = styled.img`
  position: absolute;
  top: 375px;
  left: 0;
`;

const StyledBlobPurpleYellow = styled.img`
  position: absolute;
  top: -170px;
  right: 0;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(NotPaddedMultiCarousel);
