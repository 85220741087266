import React from 'react';
import styled from 'styled-components';
import SearchIcon from 'images/venues/SearchIcon';

const KeywordSearch = ({ handleSearchInput, searchQuery }) => {
  return (
    <Wrapper>
      <KeywordInput
        type="text"
        name="search"
        autoComplete="off"
        onChange={handleSearchInput}
        value={searchQuery}
      />
      <SearchIcon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  svg {
    position: absolute;
    top: 0.75rem;
    left: 0;
    height: 1rem;
    width: 1rem;
  }
`;

const KeywordInput = styled.input`
  border-style: none;
  border-bottom-color: var(--dynamic-blue);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: var(--dynamic-dark-grey);
  font-family: 'proxima-nova', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  height: 33px;
  margin-bottom: 21px;
  max-width: 100%;
  outline: none;
  width: 100%;
  padding-left: 24px;

  &::placeholder {
    color: var(--dynamic-dark-grey);
    font-style: italic;
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: -5px;
  }
`;

export default KeywordSearch;
