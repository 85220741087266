import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import LinkedInSVG from 'images/shared/contact_form/linkedin_icon.svg';
import FBSVG from 'images/shared/contact_form/facebook_icon.svg';
import TwitterSVG from 'images/shared/contact_form/twitter_icon.svg';
import IGSVG from 'images/shared/contact_form/instagram_icon.svg';
import TikTokSVG from 'images/shared/contact_form/tiktok_icon.svg';
import VimeoSVG from 'images/shared/contact_form/vimeo_icon.svg';

class SocialMediaLinks extends PureComponent {
  state = {};

  handleHover = (e) => {
  };

  render() {
    return (
      <SocialMediaLinksWrapper
        inHamburgerMenu={this.props.inHamburgerMenu}
        onFocus={this.handleHover}>
        <TwitterLink
          onMouseEnter={this.handleHover('twitter')}
          href="https://twitter.com/DynamicEventsIE"
          target="_blank">
          <img src={TwitterSVG} alt="Twitter logo" />
        </TwitterLink>
        <FBLink
          onMouseEnter={this.handleHover('facebook')}
          href="https://www.facebook.com/DynamicEventsIreland"
          target="_blank">
          <img src={FBSVG} alt="Facebook logo" />
        </FBLink>
        <IGLink
          onMouseEnter={this.handleHover('instagram')}
          href="https://www.instagram.com/dynamiceventsie/"
          target="_blank">
          <img src={IGSVG} alt="Instagram logo" />
        </IGLink>
        <TikTokLink
          onMouseEnter={this.handleHover('tiktok')}
          href="https://www.tiktok.com/@dynamiceventsie"
          target="_blank">
          <img src={TikTokSVG} alt="TikTok logo" />
        </TikTokLink>
        <LinkedInLink
          onMouseEnter={this.handleHover('linkedin')}
          href="https://www.linkedin.com/company/dynamic-events-ireland/"
          target="_blank">
          <img src={LinkedInSVG} alt="LinkedIn logo" />
        </LinkedInLink>
        <VimeoLink
          onMouseEnter={this.handleHover('vimeo')}
          href="https://vimeo.com/dynamiceventsvideos"
          target="_blank">
          <img src={VimeoSVG} alt="Vimeo logo" />
        </VimeoLink>
      </SocialMediaLinksWrapper>
    );
  }
}

const SocialMediaLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  /* justify-content: flex-start; */
  /* width: 292px; */
  margin-bottom: 15px;

  ${({ inHamburgerMenu }) =>
    inHamburgerMenu &&
    css`
      position: absolute;
      bottom: 0;
      margin-bottom: 56px;
      left: 50%;
      transform: translateX(-50%);
    `}
`;

const SocialMediaLink = styled.a`
  background-color: var(--dynamic-dark-grey);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

const TwitterLink = styled(SocialMediaLink)``;
const FBLink = styled(SocialMediaLink)``;
const IGLink = styled(SocialMediaLink)``;
const TikTokLink = styled(SocialMediaLink)``;
const LinkedInLink = styled(SocialMediaLink)``;
const VimeoLink = styled(SocialMediaLink)``;

export default SocialMediaLinks;
