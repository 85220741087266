import React, { PureComponent, createRef } from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { InView } from 'react-intersection-observer';
import BlobYellowBlueSVG from 'images/about_us/misc/blob_yellow_blue.svg';
import BlobYellowRedSVG from 'images/about_us/misc/blob_yellow_red.svg';
import LinkedInIcon from 'images/about_us/misc/LinkedinIcon';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_background_3.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SingleCarousel, SmallCustomCarouselButtons } from 'components';

class Staff extends PureComponent {
  state = {
    currentSlide: 0,
    totalItems: undefined,
    animateLightBlueBackgroundSVG: false
  };

  handleSVG = (animateLightBlueBackgroundSVG) => this.setState({ animateLightBlueBackgroundSVG });

  carouselRef = createRef();

  componentDidMount() {
    this.setCarouselTotalItems();
  }

  setCarouselTotalItems = () => {
    this.setState((prevState) => ({
      ...prevState,
      totalItems: this.carouselRef.current.itemsRef.length
    }));
  };

  goToPreviousSlide = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentSlide: prevState.currentSlide - 1
    }));
  };

  goToNextSlide = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentSlide: prevState.currentSlide + 1
    }));
  };

  handleSlideChange = (currentSlide) => {
    this.setState((prevState) => {
      if (currentSlide !== prevState.currentSlide) {
        return {
          ...prevState,
          currentSlide
        };
      }
    });
  };
  render() {
    const { viewportWidth } = this.props;
    const { animateLightBlueBackgroundSVG, currentSlide, totalItems } = this.state;
    return (
      <OuterWrapper>
        <LightBlueBackground
          animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
          src={LightBlueBackgroundSVG}
          alt=""
          // animationDuration={`${viewportWidth / 1920}s`}
          animationDuration="1.15s"
        />
        <BlobYellowRed src={BlobYellowRedSVG} alt="yellow and red blob" />
        <BlobYellowBlue src={BlobYellowBlueSVG} alt="yellow and blue blob" />
        <InnerWrapper>
          <StaticQuery
            query={graphql`
              {
                staffPhoto: file(relativePath: { eq: "about_us/misc/group_shot_close.jpg" }) {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 50
                      placeholder: TRACED_SVG
                      width: 700
                      pngOptions: { compressionSpeed: 10 }
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                staffGroupPhotos: allFile(
                  filter: { ext: { eq: ".md" }, sourceInstanceName: { eq: "staff_group" } }
                  sort: { relativeDirectory: ASC }
                ) {
                  edges {
                    node {
                      childMarkdownRemark {
                        rawMarkdownBody
                        frontmatter {
                          alt
                          src {
                            childImageSharp {
                              gatsbyImageData(
                                width: 700
                                quality: 90
                                placeholder: TRACED_SVG
                                pngOptions: { compressionSpeed: 10 }
                                formats: [AUTO, WEBP],
                                transformOptions: {cropFocus: SOUTH}
                              )
                            }
                          }
                        }
                      }
                    }
                  }
                }
                staffProfiles: allFile(
                  filter: { sourceInstanceName: { eq: "staff_profiles" }, ext: { eq: ".md" } }
                  sort: { relativeDirectory: ASC }
                ) {
                  edges {
                    node {
                      childMarkdownRemark {
                        rawMarkdownBody
                        frontmatter {
                          name
                          role
                          portrait {
                            childImageSharp {
                              gatsbyImageData(
                                width: 350
                                height: 330
                                quality: 90
                                placeholder: TRACED_SVG
                                formats: [AUTO, WEBP],
                                transformOptions: {cropFocus: NORTH}
                              )
                            }
                          }
                          linkedinProfile
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={({ staffPhoto, staffProfiles, staffGroupPhotos }) => (
              <>
                {staffPhoto && (
                  <OurPeopleMakeUsWhoWeAre>
                    <div>
                      <h3>Our people make us who we are</h3>
                      <p>
                        We are known to be event innovators, perfectionists and fun people to work
                        with, delivering game-changing experiences.
                        <br />
                        <br />
                        Our expert and experienced event professionals come from a wide range of
                        backgrounds, including design, technology, marketing, event and project
                        management.
                        <br />
                        <br />
                        This diversity helps us meet our client's expectations when creating bespoke
                        event experiences.
                      </p>
                    </div>
                    {staffGroupPhotos && (
                      <CarouselWrapper>
                        <SingleCarousel
                          carouselImgs={staffGroupPhotos.edges.map(
                            (staffGroupPhotos) =>
                              staffGroupPhotos.node.childMarkdownRemark.frontmatter
                          )}
                          currentSlide={currentSlide}
                          totalItems={totalItems}
                          handleSlideChange={this.handleSlideChange}
                          innerRef={this.carouselRef}
                          showIndicators
                          flip></SingleCarousel>
                      </CarouselWrapper>
                    )}
                  </OurPeopleMakeUsWhoWeAre>
                )}
                <InView onChange={this.handleSVG} triggerOnce>
                  <CurrentStaff>
                    {staffProfiles &&
                      staffProfiles.edges.map((staffProfile) => {
                        const { frontmatter, rawMarkdownBody } =
                          staffProfile.node.childMarkdownRemark;
                        const { name, role, portrait, linkedinProfile } = frontmatter;
                        return (
                          <StaffProfile key={name}>
                            <Portrait image={getImage(portrait)} />
                            <div>
                              <h3>{name}</h3>
                              <p>{role}</p>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: rawMarkdownBody
                                }}
                              />
                              {linkedinProfile && (
                                <LinkedInProfile href={linkedinProfile} target="_blank">
                                  <StyledLinkedInIcon />
                                </LinkedInProfile>
                              )}
                            </div>
                          </StaffProfile>
                        );
                      })}
                  </CurrentStaff>
                </InView>
              </>
            )}
          />
        </InnerWrapper>
      </OuterWrapper>
    );
  }
}

const StyledLinkedInIcon = styled(LinkedInIcon)``;

const LightBlueBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  top: 265px;
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

const LinkedInProfile = styled.a`
  position: absolute;
  right: 20px;
  bottom: 20px;

  &:hover ${StyledLinkedInIcon} {
    circle {
      fill: var(--dynamic-blue);
      transition: fill 0.15s ease-in-out;
    }
  }

  &:not(:hover) ${StyledLinkedInIcon} {
    circle {
      fill: var(--dynamic-dark-grey);
      transition: fill 0.15s ease-in-out;
    }
  }
`;

const CurrentStaff = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`;

const StaffProfile = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 16px rgba(189, 189, 189, 0.8);
  flex-basis: 350px;
  margin: 0 auto 25px;
  min-height: 780px;
  position: relative;

  > div:last-of-type {
    padding: 9% 6.5% 6.5%;
    letter-spacing: 0.01em;
    font-family: 'proxima-nova', sans-serif;

    h3 {
      color: var(--dynamic-dark-grey);
      font-size: 1.313rem;
    }

    p:first-of-type {
      color: #bdbdbd;
      margin-bottom: 1em;
      line-height: 1em;
      font-weight: 500;
    }

    p:last-of-type {
      line-height: 1.313em;
    }
  }
`;

const Portrait = styled(GatsbyImage)`
  border-radius: 10px 10px 0 0;
`;

const OuterWrapper = styled.section`
  position: relative;
  padding: 0 3.2%;

  @media screen and (min-width: 1170px) {
    padding: unset;
  }
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;

  @media screen and (min-width: 1143px) {
    max-width: 1100px;
  }
`;

const OurPeopleMakeUsWhoWeAre = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  color: var(--dynamic-dark-grey);
  margin: 14.54% auto 12.72%;

  > div:first-of-type {
    max-width: 90%;
    margin: 0 auto 2em;

    @media screen and (min-width: 500px) {
      max-width: 450px;
    }
  }

  h3 {
    font-family: adelle, serif;
    font-size: 1.313rem;
    margin-bottom: 0.875em;
  }

  p {
    font-family: 'proxima-nova', sans-serif;
    font-weight: 500;
    line-height: 1.313em;
  }

  @media screen and (min-width: 800px) {
    flex-direction: row;
    text-align: left;

    > div:first-of-type {
      max-width: 350px;
      margin: 0 3.4% 0 0;
    }
  }
`;

const BlobYellowRed = styled.img`
  position: absolute;
  top: 318px;
  left: 0;
`;

const BlobYellowBlue = styled.img`
  position: absolute;
  top: 1200px;
  right: 0;
  z-index: 2;
`;

const CarouselWrapper = styled.div`
  max-width: 450px;
  position: relative;
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: 800px) {
    max-width: 700px;
    margin: unset;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Staff);
