import React, { PureComponent, createRef } from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { SingleCarousel, SmallCustomCarouselButtons } from 'components';
import BlobYellowRedSVG from 'images/activity/misc/blob_yellow_red.svg';
import BlobRedYellowSVG from 'images/event_management/blob_red_yellow.svg';
import BlobPurpleBlueSVG from 'images/event_management/blob_purple_blue.svg';
import BlobRedBlueSVG from 'images/event_management/blob_red_blue.svg';
import BlobYellowPurpleSVG from 'images/event_management/blob_yellow_purple.svg';

class ProgramaticallyCreatedPageOverview extends PureComponent {
  state = {
    currentSlide: 0,
    totalItems: undefined
  };

  carouselRef = createRef();

  componentDidMount() {
    if (this.props.carouselImgs && this.props.carouselImgs.length) {
      this.setCarouselTotalItems();
    }
  }

  setCarouselTotalItems = () => {
    this.setState((prevState) => ({
      ...prevState,
      totalItems: this.carouselRef.current.itemsRef.length
    }));
  };

  goToPreviousSlide = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentSlide: prevState.currentSlide - 1
    }));
  };

  goToNextSlide = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentSlide: prevState.currentSlide + 1
    }));
  };

  handleSlideChange = (currentSlide) => {
    this.setState((prevState) => {
      if (currentSlide !== prevState.currentSlide) {
        return {
          ...prevState,
          currentSlide
        };
      }
    });
  };

  render() {
    const {
      children: activityOrCaseStudyOrVenueInfo,
      pathname,
      carouselImgs,
      viewportWidth
    } = this.props;

    const { currentSlide, totalItems } = this.state;

    return (
      <OuterWrapper>
        {pathname.includes('/team-building/') && (
          <StyledBlobYellowRed src={BlobYellowRedSVG} alt="" />
        )}
        {viewportWidth > 735 && viewportWidth < 1100 && (
          <>
            <BlobRedYellow src={BlobRedYellowSVG} alt="red and yellow blob" />
            <BlobPurpleBlue src={BlobPurpleBlueSVG} alt="purple and blue blob" />
            <BlobRedBlue src={BlobRedBlueSVG} alt="red and blue blob" />
            <BlobYellowPurple src={BlobYellowPurpleSVG} alt="yellow and purple blob" />
          </>
        )}
        <InnerWrapper>
          {activityOrCaseStudyOrVenueInfo && <Info>{activityOrCaseStudyOrVenueInfo}</Info>}
          {carouselImgs && (
            <CarouselWrapper>
              <SingleCarousel
                carouselImgs={carouselImgs.map((carouselImg) => carouselImg)}
                currentSlide={currentSlide}
                totalItems={totalItems}
                handleSlideChange={this.handleSlideChange}
                innerRef={this.carouselRef}
                showIndicators
                flip>
                <SmallCustomCarouselButtons
                  goToPreviousSlide={this.goToPreviousSlide}
                  goToNextSlide={this.goToNextSlide}
                  disableLeftArrow={currentSlide === 0}
                  disableRightArrow={currentSlide === totalItems - 1}
                  leftArrowOffset={
                    viewportWidth < 1100 ? 'calc(50% - 45px)' : 'calc(29.75% - 43px)'
                  }
                  rightArrowOffset={viewportWidth < 1100 ? 'calc(50% - 45px)' : 'calc(69% - 43px)'}
                />
              </SingleCarousel>
            </CarouselWrapper>
          )}
        </InnerWrapper>
      </OuterWrapper>
    );
  }
}

const OuterWrapper = styled.section`
  padding-top: 11.1%;
  position: relative;

  @media screen and (min-width: 1440px) {
    padding-top: 160px;
  }
`;

const StyledBlobYellowRed = styled.img`
  position: absolute;
  bottom: -275px;
  right: 0;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  max-width: 1440px;
  margin: 0 3.2% 5.76%;

  @media screen and (min-width: 566px) {
    margin: 0 auto 5.76%;
  }

  @media screen and (min-width: 1100px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }

  @media screen and (min-width: 1440px) {
    margin: 0 auto 83px;
  }
`;

const Info = styled.div`
  max-width: 540px;
  width: 100%;

  @media screen and (min-width: 1100px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const CarouselWrapper = styled.div`
  max-height: 540px;
  max-width: 540px;
  position: relative;
  width: 100%;
  margin-bottom: 60px;

  @media screen and (min-width: 1100px) {
    max-height: 49.31vw;
    max-width: 49.31%;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1440px) {
    max-height: 49.31%;
  }
`;

const BlobRedYellow = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;
const BlobPurpleBlue = styled.img`
  left: 0;
  position: absolute;
  top: 300px;
`;
const BlobRedBlue = styled.img`
  position: absolute;
  right: 0;
  top: 700px;
`;
const BlobYellowPurple = styled.img`
  left: 0;
  position: absolute;
  top: 1000px;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  ProgramaticallyCreatedPageOverview
);
