import * as React from 'react';
function SvgPageNotFoundIcon(props) {
  return (
    <svg width={674} height={248} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M585.344 230v-42.069H474v-46.207L562.307 0h83.421v135.862H674v52.069h-28.272V230h-60.384zm0-178.276l-53.054 84.138h53.054V51.724z"
        fill="url(#page-not-found-icon_svg__paint0_linear_127_114)"
      />
      <path
        d="M111.344 230v-42.069H0v-46.207L88.307 0h83.421v135.862H200v52.069h-28.272V230h-60.384zm0-178.276L58.29 135.862h53.054V51.724z"
        fill="url(#page-not-found-icon_svg__paint1_linear_127_114)"
      />
      <path
        d="M237 128.827C237 68.032 268.818 10 337 10s100 58.032 100 118.827C437 189.623 405.182 248 337 248s-100-58.377-100-119.173z"
        fill="url(#page-not-found-icon_svg__paint2_radial_127_114)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M336.994 105.431L372.425 70l23.567 23.567-35.431 35.431L396 164.438l-23.566 23.566-35.44-35.439-35.427 35.428L278 164.426l35.428-35.428-35.419-35.42 23.566-23.567 35.419 35.42z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="page-not-found-icon_svg__paint0_linear_127_114"
          x1={574}
          y1={0}
          x2={574}
          y2={230}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C1BEAC" />
          <stop offset={1} stopColor="#CE9200" />
        </linearGradient>
        <linearGradient
          id="page-not-found-icon_svg__paint1_linear_127_114"
          x1={100}
          y1={0}
          x2={100}
          y2={230}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C1BEAC" />
          <stop offset={1} stopColor="#CE9200" />
        </linearGradient>
        <radialGradient
          id="page-not-found-icon_svg__paint2_radial_127_114"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 119 -100 0 337 129)">
          <stop stopColor="#EFEDE4" />
          <stop offset={0.525} stopColor="#EDB40D" />
          <stop offset={0.995} stopColor="#CE9200" />
        </radialGradient>
      </defs>
    </svg>
  );
}
export default SvgPageNotFoundIcon;
