import * as React from 'react';
function SvgDuration(props) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20 35.6c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14zM20 21.6l5.8-5.8"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 22.6a1 1 0 100-2 1 1 0 000 2zM20 10.8v10.8M20 4.4v3M17.5 4.4h5"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgDuration;
