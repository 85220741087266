import React, { useState, useEffect } from 'react';
import Tooltip from './Tooltip';
import { KeywordSearch } from 'components';
import qs from 'qs';
import styled, { css } from 'styled-components';

const tooltipContent = [
  {
    title: 'In Person and Fully Managed',
    text: 'A Fully Managed Event is where we have an event manager and support staff physically attend you event at your office, hotel or venue.'
  },
  {
    title: 'In Person with Remote Host',
    text: 'A remotely run event is where there is no need for an event manager on site. All of the participants are together at your venue or office. We deliver the briefing and results via video conference and support the activities remotely. Our event manager will remotely monitor your event online to ensure everything is running smoothly.'
  },
  {
    title: 'Fully Virtual',
    text: 'An online or fully virtual Event is where all participants, host and support staff attend via their own video conference connection (i.e. a Zoom/MS Teams/Webex/Hangouts call).'
  }
];

const customSortArr = [
  'All Format/Support Options',
  'In Person and Fully Managed',
  'In Person with Remote Host',
  'Fully Virtual'
];

const TeamBuildingFilters = ({
  filterCategories,
  filterValues,
  setFilterValues,
  handleFilterClick,
  handleSearchInput,
  searchQuery
}) => {
  const [currentlyOpenTooltip, setCurrentlyOpenTooltip] = useState('');

  useEffect(() => {
    const queryString = window.location.search?.split('?')[1];
    if (queryString) {
      let _paramFilterValues = qs.parse(queryString);
      const _filterValues = Object.entries(filterValues).reduce((acc, [filterTitle, nestedObj]) => {
        acc[filterTitle] = Object.entries(nestedObj).reduce((_acc, [prop, value]) => {
          if (_paramFilterValues[filterTitle]) {
            if (_paramFilterValues[filterTitle][prop] === 'true') {
              _acc[prop] = true;
            } else {
              _acc[prop] = false;
            }
          } else if (prop.includes('All')) {
            _acc[prop] = true;
          } else {
            _acc[prop] = false;
          }
          return _acc;
        }, {});
        return acc;
      }, {});

      setFilterValues(_filterValues);
    }
  }, []);

  const getFilterJSX = ({ filterCategory, filter, i }) => {
    const [isMouseOverAnyTooltip, setIsMouseOverAnyTooltip] = useState(false);
    return (
      <Label isMouseOverAnyTooltip={isMouseOverAnyTooltip} key={filter}>
        <HiddenDefaultCheckbox
          name={filter}
          onChange={(e) => handleFilterClick({ e, filterCategory, i })}
          type="checkbox"
          checked={filterValues[filterCategory][filter]}
        />
        <CustomCheckbox>
          <Filter>
            {filter}
            {filterCategory === 'formatOfDeliveryAndEventSupport' && i !== 0 && (
              <Tooltip
                title={tooltipContent[i - 1].title}
                text={tooltipContent[i - 1].text}
                currentlyOpenTooltip={currentlyOpenTooltip}
                setCurrentlyOpenTooltip={setCurrentlyOpenTooltip}
                setIsMouseOverAnyTooltip={setIsMouseOverAnyTooltip}
              />
            )}
          </Filter>
        </CustomCheckbox>
        <Amount>
          {i === 0
            ? filterCategories.filter((obj) => obj[filterCategory]).length
            : filterCategories
                .map((obj) => obj[filterCategory])
                .filter((filters) => filters.includes(filter)).length}
        </Amount>
      </Label>
    );
  };

  return (
    <Wrapper>
      <h3>Search by keyword:</h3>
      <KeywordSearch searchQuery={searchQuery} handleSearchInput={handleSearchInput} />
      <h3>Filter by:</h3>
      <h2>
        Format of Delivery
        <br />
        and Event Support:
      </h2>
      {Object.keys(filterValues.formatOfDeliveryAndEventSupport)
        .sort((a, b) => customSortArr.indexOf(a) - customSortArr.indexOf(b))
        .map((filter, i) =>
          getFilterJSX({
            filterCategory: 'formatOfDeliveryAndEventSupport',
            filter,
            i
          })
        )}
      <hr />
      <h2>Location:</h2>
      {Object.keys(filterValues.activityLocation).map((filter, i) =>
        getFilterJSX({
          filterCategory: 'activityLocation',
          filter,
          i
        })
      )}
      <hr />
      <h2>Theme:</h2>
      {Object.keys(filterValues.themes).map((filter, i) =>
        getFilterJSX({
          filterCategory: 'themes',
          filter,
          i
        })
      )}
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  background-color: #fff;
  border-radius: 0.625rem;
  border: 0.063rem solid var(--dynamic-blue);
  font-family: 'proxima-nova', sans-serif;
  margin: 0 auto 1.85rem;
  max-width: 20.625rem;
  padding: 1.688rem;
  width: 100%;
  z-index: 2;

  h3 {
    font-size: 1.25rem;
    font-family: adelle, serif;
    font-weight: 500;
    margin-bottom: 1em;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  hr {
    border-top: 0.063rem solid var(--dynamic-blue);
    margin: 1.5rem 0;
  }

  @media screen and (min-width: 54.375rem) {
    margin-right: 1.25rem;
  }
`;

export const SelectQuestionCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 0.063rem solid #bdbdbd;
  cursor: pointer;
  height: 1rem;
  margin-top: 0.125rem;
  outline: none;
  position: absolute;
  transition: all 0.1s;
  width: 1rem;

  &:focus {
    border: 0.125rem solid var(--dynamic-dark-blue);
  }

  &:checked {
    border: 0.063rem solid var(--dynamic-dark-blue);
  }

  &:checked:before {
    color: var(--dynamic-dark-blue);
    content: '✓';
    font-size: 0.8rem;
    height: 0.75em;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37%, -60%);
    width: 0.75em;
  }
`;

const Label = styled.label`
  color: var(--dynamic-dark-grey);
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.375em;
  margin: 1.143em 0;
  position: relative;
  transition: background-color 0.1s ease-in-out;
  user-select: none;
  width: 100%;

  & input + span {
    background-color: #fff;
  }

  ${({ isMouseOverAnyTooltip }) =>
    !isMouseOverAnyTooltip &&
    css`
      &:hover input + span {
        background-color: rgba(238, 238, 238, 0.35);
      }
    `}

  & input:checked + span {
    background-color: var(--dynamic-dark-blue);
  }

  & input:checked + span:after {
    display: block;
  }
`;

const HiddenDefaultCheckbox = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`;

const CustomCheckbox = styled.span`
  background-color: rgba(238, 238, 238, 0.35);
  border-radius: 0.125rem;
  border: 0.063rem solid var(--dynamic-dark-blue);
  cursor: pointer;
  height: 1.563rem;
  position: absolute;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  width: 1.563rem;

  &:after {
    content: '';
    display: none;
    position: absolute;
  }

  &:after {
    border-color: #fff;
    border-style: solid;
    border-width: 0 0.188rem 0.188rem 0;
    height: 0.781rem;
    left: 0.5rem;
    top: 0.25rem;
    transform: rotate(45deg);
    width: 0.438rem;
  }
`;

const Filter = styled.span`
  left: 2.125rem;
  letter-spacing: 0.02rem;
  position: absolute;
  top: 0.2rem;
  white-space: nowrap;
`;

const Amount = styled.span`
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0.313rem;
`;

export default TeamBuildingFilters;
