import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

function LoadingSpinner({
  initial = { opacity: 0 },
  animate = { opacity: 1 },
  exit = { opacity: 0 },
  style = {
    width: '5rem',
    color: '#fff'
  }
}) {
  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      initial={initial}
      animate={animate}
      exit={exit}
      style={style}
      display="block">
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke={style.color}
        strokeWidth={10}
        r={35}
        strokeDasharray="164.93361431346415 56.97787143782138"
      />
    </Wrapper>
  );
}

const spin = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const Wrapper = styled(motion.svg)`
  animation: ${spin} 650ms linear infinite;
`;

export default LoadingSpinner;
