import React, { PureComponent, createRef } from 'react';
import { Button, Card } from 'components';
import { scroller } from 'react-scroll';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import ArrowSVG from 'images/shared/misc/left_arrow.svg';

class Cards extends PureComponent {
  state = {
    activitiesPerPage: 9,
    currentPage: 1,
    showTheseCards: []
  };

  leftArrow = createRef();

  rightArrow = createRef();

  static getDerivedStateFromProps({ showTheseCards }) {
    return {
      showTheseCards
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /* Resets the currentPage to 1 if you're currently on another page and you click on another
    filter */
    if (prevState.showTheseCards !== this.props.showTheseCards) {
      this.setState({
        currentPage: 1
      });
    }
  }

  scrollToTopOfGrid = (e, callback) => {
    const scrollDuration = 1000;
    const { viewportWidth } = this.props;

    scroller.scrollTo('top-of-grid', {
      duration: scrollDuration,
      delay: 0,
      offset:
        viewportWidth < 360 ? -90 : viewportWidth < 375 ? -96 : viewportWidth < 967 ? 114 : -146,
      smooth: 'easeInOutQuint'
    });

    callback(e);
  };

  handlePageNumberClick = (e) => {
    this.setState({
      currentPage: Number(e.target.id)
    });
  };

  handleArrowClick = (e) => {
    if (e.target === this.leftArrow.current) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1
      }));
    } else if (e.target === this.rightArrow.current) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1
      }));
    }
  };

  render() {
    const { pathname } = this.props;
    const { showTheseCards, currentPage, activitiesPerPage } = this.state;

    const indexOfLastActivity = currentPage * activitiesPerPage;
    const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
    const paginatedCards = showTheseCards.slice(indexOfFirstActivity, indexOfLastActivity);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(showTheseCards.length / activitiesPerPage); i++) {
      pageNumbers.push(i);
    }
    return (
      showTheseCards && (
        <Wrapper name="top-of-grid" pathname={pathname}>
          <Grid gridIsEmpty={!showTheseCards.length} pathname={pathname}>
            {showTheseCards.length ? (
              paginatedCards.map(({ node: activity }) => {
                const {
                  title,
                  summary,
                  featuredImg,
                  graphic,
                  formatOfDeliveryAndEventSupport,
                  date
                } = activity.childMarkdownRemark.frontmatter;
                return (
                  <Card
                    key={activity.id}
                    title={title
                      .split(' ')
                      .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
                      .join(' ')}
                    summary={summary}
                    featuredImg={featuredImg}
                    graphic={graphic}
                    formatOfDeliveryAndEventSupport={formatOfDeliveryAndEventSupport}
                    date={date}
                    pathname={pathname}
                  />
                );
              })
            ) : (
              <NoCards>
                There are currently no{' '}
                {pathname === '/team-building/'
                  ? 'activities'
                  : pathname === '/venue-partners/'
                  ? 'venues'
                  : pathname === '/our-work/'
                  ? 'case studies'
                  : 'blog posts'}{' '}
                to show for this filter.
                <br />
                Please select another.
              </NoCards>
            )}
          </Grid>
          {showTheseCards.length > activitiesPerPage && (
            <PageNumbers>
              <ButtonExt
                ref={this.leftArrow}
                width="40px"
                ariaLabel="left arrow"
                borderRadius="10px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                transformOrigin="center right"
                scale="1.125"
                handleClick={(e) => {
                  this.scrollToTopOfGrid(e, this.handleArrowClick);
                }}
                disabled={currentPage === pageNumbers[0]}>
                <img src={ArrowSVG} alt="left arrow" style={{ marginRight: '5px' }} />
              </ButtonExt>
              {pageNumbers.map((number) => (
                <PageNumber
                  key={number}
                  id={number}
                  onClick={(e) => {
                    this.scrollToTopOfGrid(e, this.handlePageNumberClick);
                  }}
                  highlight={number === currentPage}>
                  {number}
                </PageNumber>
              ))}
              <ButtonExt
                ariaLabel="right arrow"
                ref={this.rightArrow}
                width="40px"
                borderRadius="10px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                transformOrigin="center left"
                scale="1.125"
                handleClick={(e) => {
                  this.scrollToTopOfGrid(e, this.handleArrowClick);
                }}
                disabled={currentPage === pageNumbers.length}>
                <img
                  src={ArrowSVG}
                  alt="right arrow"
                  style={{ marginLeft: '5px', transform: 'rotate(180deg)' }}
                />
              </ButtonExt>
            </PageNumbers>
          )}
        </Wrapper>
      )
    );
  }
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${({ pathname }) =>
    pathname === '/team-building/' || pathname === '/our-work/' || pathname === '/team-training/' ? '1030px' : '1100px'};
  position: relative;
  width: 100%;
  z-index: 1;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      auto,
      ${({ gridIsEmpty, pathname }) =>
        gridIsEmpty
          ? 'auto'
          : pathname === '/team-building/' || pathname === '/our-work/' || pathname === '/team-training/'
          ? '330px'
          : pathname === '/venue-partners/'
          ? '330px'
          : '350px'}
    )
  );
  grid-auto-rows: minmax(420px, auto);
  grid-gap: ${({ pathname }) =>
    pathname === '/team-building/' || pathname === '/our-work/' || pathname === '/team-training/'
      ? '30px 20px'
      : pathname === '/venue-partners/'
      ? '30px 20px'
      : '30px 25px'};
  justify-content: center;
`;

const NoCards = styled.div`
  color: var(--dynamic-blue);
  font-family: adelle, serif;
  font-size: 2rem;
  letter-spacing: 0.01em;
  text-align: center;
`;

const PageNumbers = styled.ul`
  align-items: center;
  align-self: center;
  display: flex;
  list-style: none;
  margin-top: 28px;

  @media screen and (min-width: 1256px) {
    align-self: flex-end;
  }

  button,
  li {
    margin: 0 0.66em;
  }
`;

const PageNumber = styled.li`
  color: #bdbdbd;
  cursor: pointer;
  font-family: 'proxima-nova', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  margin-right: 0.3em;
  user-select: none;
  position: relative;
  top: 1px;

  ${({ highlight }) =>
    highlight &&
    css`
      color: var(--dynamic-dark-grey);
    `}
`;
const ButtonExt = styled(React.forwardRef((props, ref) => <Button {...props} innerRef={ref} />))``;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Cards);
