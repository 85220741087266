import React from 'react';
import { Link } from 'gatsby';
import sizes from 'react-sizes';
import DynamicEventsLogoSVG from 'images/shared/header/dynamic_events_logo.svg';
import DynamicEventsLogoWithWhiteLegSVG from 'images/shared/header/dynamic_events_logo_white_leg.svg';
import styled, { css } from 'styled-components';

const DynamicEventsLogo = ({ makeHeaderSticky, viewportWidth, onMobile, hamBurgerMenuIsOpen }) => {
  return (
    <Wrapper onMobile={onMobile}>
      <Link to="/">
        <Logo
          src={hamBurgerMenuIsOpen ? DynamicEventsLogoWithWhiteLegSVG : DynamicEventsLogoSVG}
          makeHeaderSticky={makeHeaderSticky}
          viewportWidth={viewportWidth}
          hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}
          alt="Dynamic Events Logo"
        />
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ onMobile }) =>
    onMobile &&
    css`
      position: absolute;
      left: 50%;
      transform: translate3d(-75%, 13px, 0);

      @media screen and (min-width: 375px) {
        transform: translate3d(-50%, 13px, 0);
      }
    `}
`;

const Logo = styled.img`
  transition: width 0.5s ease-in-out;

  ${({ makeHeaderSticky, viewportWidth, hamBurgerMenuIsOpen }) =>
    !hamBurgerMenuIsOpen && viewportWidth < 360
      ? css`
          width: 112px;
        `
      : !hamBurgerMenuIsOpen && viewportWidth < 375
      ? css`
          width: 130px;
        `
      : !hamBurgerMenuIsOpen && viewportWidth < 967
      ? css`
          width: 150px;
        `
      : makeHeaderSticky || viewportWidth < 1200
      ? css`
          width: 205px;
        `
      : css`
          width: 280px;
        `}
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(DynamicEventsLogo);
