import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { GetButton } from 'components';
import { Button } from 'components';
import EventTypeIcon from 'images/activity/info_icons/EventType';
import ApproachIcon from 'images/activity/info_icons/Approach';
import DurationIcon from 'images/activity/info_icons/Duration';
import NumbersIcon from 'images/activity/info_icons/Numbers';
import LocationIcon from 'images/activity/info_icons/Location';

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  ({ handleClick, info, buttonLinkPath, viewportWidth }) => {
    const {
      fees,
      formatOfDeliveryAndEventSupport,
      approach,
      duration,
      numbers,
      activityLocation,
      html,
      keyBusinessBenefits
    } = info;
    const showGetQuoteButton = fees && Object.values(fees).length > 0;
    const strippedHtml = html.replaceAll('<p> </p>', '');
    /* const showGetQuoteButton = fees && Object.values(fees)?.some((fee) => fee?.setupFee); */
    return (
      <>
        <Icons>
          <Icon>
            <BlueArea>
              <EventTypeIcon />
              Event Type
            </BlueArea>
            <WhiteArea
              dangerouslySetInnerHTML={{
                __html: [
                  ...new Set(
                    formatOfDeliveryAndEventSupport.map((format) =>
                      format.includes('In Person') ? 'In Person' : 'Virtual'
                    )
                  )
                ].join(' Or ')
              }}></WhiteArea>
          </Icon>
          <Icon>
            <BlueArea>
              <ApproachIcon />
              Approach
            </BlueArea>
            <WhiteArea>{approach}</WhiteArea>
          </Icon>
          <Icon>
            <BlueArea>
              <DurationIcon />
              Duration
            </BlueArea>
            <WhiteArea>{duration}</WhiteArea>
          </Icon>
          <Icon>
            <BlueArea>
              <NumbersIcon />
              Numbers
            </BlueArea>
            <WhiteArea>{numbers}</WhiteArea>
          </Icon>
          {activityLocation && (
            <Icon>
              <BlueArea>
                <LocationIcon />
                Location
              </BlueArea>
              <WhiteArea
                dangerouslySetInnerHTML={{
                  __html: activityLocation.join(' Or ')
                }}></WhiteArea>
            </Icon>
          )}
        </Icons>
        <Text>
          <h3>More Info</h3>
          <div dangerouslySetInnerHTML={{ __html: strippedHtml }} />
          <h3>Key Business Benefits</h3>
          <ul>
            {keyBusinessBenefits.map((benefit) => (
              <li key={benefit}>{benefit}</li>
            ))}
          </ul>
        </Text>
        <ButtonWrapper>
          {showGetQuoteButton && (
            <GetButton
              buttonLinkPath={buttonLinkPath}
              ariaLabel="get quote"
              buttonText="Get Quote"
              buttonWidth="200px"
              buttonHeight="44px"
              handleClick={handleClick}
              shine
            />
          )}
          {buttonLinkPath && (
            <GetButton
              buttonLinkPath={buttonLinkPath}
              ariaLabel="download brochure"
              buttonText="Download Brochure"
              buttonWidth="200px"
              buttonHeight="44px"
            />
          )}
        </ButtonWrapper>
      </>
    );
  }
);

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    margin-top: 0;
  }
`;

const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 1;
  max-width: 540px;
  margin: 0 auto 20px;
  align-content: center;

  @media screen and (min-width: 566px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media screen and (min-width: 1100px) {
    margin: 0 auto 24px;
  }

  @media screen and (min-width: 1140px) {
    max-width: 100%;
  }
`;

const Icon = styled.div`
  width: 100px;
  height: 140px;
  display: grid;
  grid-template-rows: 2.5fr 1fr;
  margin: 0 5px 12px;

  @media screen and (min-width: 566px) {
    margin: 0 0 12px;
  }

  > div {
    border: 1px solid var(--dynamic-blue);
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BlueArea = styled.div`
  background-color: var(--dynamic-blue);
  color: #fff;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;

  svg {
    margin-bottom: 8px;
  }
`;

const WhiteArea = styled.div`
  background-color: #fff;
  color: var(--dynamic-blue);
  border-radius: 0 0 10px 10px;
  padding: 0.5em;
  height: 46px;
  text-align: center;
  li {
    padding-bottom: 1rem;
  }
`;

/* TODO: Refactor with Activity Info Text */
const Text = styled.div`
  p {
    font-size: 1rem;
    line-height: 1.313em;
    margin-bottom: 2.125em;
    color: var(--dynamic-dark-grey);
  }

  a {
    color: var(--dynamic-dark-blue);
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: adelle, serif;
    margin: -0.075em 0 0.25em;
  }

  > ul {
    list-style: none;
    margin: 0;
  }

  > div p {
    margin-bottom: 1em;
    line-height: 1.313em;
  }

  ul {
    margin-left: 1rem;
    margin-bottom: 1em;
  }

  ul {
    li {
      padding-bottom: 0.5rem;
    }
  }

  ol {
    li {
      padding-bottom: 0.5rem;
    }
  }

  > div,
  > ul {
    margin-bottom: 3.4em;
    font-family: 'proxima-nova', sans-serif;
  }

  figure,
  > span.gatsby-resp-image-wrapper,
  div.video-without-caption,
  a.inline-img-link {
    margin: 2rem 0;
  }

  a.inline-img-link {
    display: block;
  }

  figcaption {
    font-size: 0.85rem;
    margin-top: 0.25em;
    color: #3b444b;
    text-align: center;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (min-width: 1100px) {
    > div p {
      width: 96%;
    }
  }
`;
