import * as React from 'react';
function SvgLocation(props) {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.9 34.9h24.2M28 13.1c0 4.4-8 18-8 18s-8-13.6-8-18 3.6-8 8-8 8 3.6 8 8z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 16.1a3 3 0 100-6 3 3 0 000 6z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgLocation;
