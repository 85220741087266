import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import { withPrefix } from 'gatsby';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import VideoPoster from './video-poster.jpg';

class HeroVideo extends PureComponent {
  render() {
    return (
      <AspectRatioWrapper
        muted
        autoPlay
        playsInline
        loop
        poster={VideoPoster}>
        <source
          src={withPrefix(`videos/${this.props.videoTitle}${isMobile ? '_mobile' : '_desktop'}.mp4`)}
          type="video/mp4"
        />
      </AspectRatioWrapper>
    );
  }
}

const AspectRatioWrapper = styled.video`
  height: 100vh;
  object-fit: cover;
  object-position: center;
  position: relative;
  width: 100vw;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(HeroVideo);
