import addAllFilterToEachFilterCategory from './addAllFilterToEachFilterCategory.js';

const getFiltersInitialState = (filterCategories) => {
  const acc = Object.keys(filterCategories[0]).reduce((_acc, filterCategory) => {
    _acc[filterCategory] ? _acc : (_acc[`${filterCategory}`] = {});
    addAllFilterToEachFilterCategory({ filterCategory, _acc });
    return _acc;
  }, {});

  const initialState = filterCategories.reduce((acc, node) => {
    Object.keys(node).forEach((filterCategory) => {
      const filter = node[filterCategory];
      if (filter) {
        filter.forEach((filter) => {
          if (acc[filterCategory][filter]) {
            return;
          } else {
            acc[filterCategory][filter] = false;
          }
        });
      }
    });
    return acc;
  }, acc);

  return initialState;
};

export default getFiltersInitialState;
