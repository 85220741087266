const getCorrespondingFilterCategoryState = ({
  filterCategoryValues,
  otherFilterCategoryValues,
  filterCategory,
  countiesDividedByRegion,
  target
}) => {
  const previouslySelectedFilterCategories = Object.keys(filterCategoryValues)
    .slice(1)
    .filter((key) => filterCategoryValues[key] === true);

  const previouslySelectedOtherFilterCategories = Object.keys(otherFilterCategoryValues)
    .slice(1)
    .filter((key) => otherFilterCategoryValues[key] === true);

  return Object.keys(filterCategoryValues).reduce((acc, filter) => {
    // If you are unchecking a category checkbox, and it corresponds
    // to another category checkbox, then set that checkbox to false also.
    if (filter !== 'All Regions') {
      if (
        !target.checked &&
        countiesDividedByRegion[
          `${filterCategory === 'county' ? filter.toLowerCase() : target.name.toLowerCase()}`
        ].includes(filterCategory === 'county' ? target.name : filter) &&
        previouslySelectedFilterCategories.includes(filter)
      ) {
        if (filterCategory === 'county') {
          const remainingSelectedFilters = previouslySelectedOtherFilterCategories.filter(
            (location) => location !== target.name
          );
          if (
            countiesDividedByRegion[`${filter.toLowerCase()}`].some((county) =>
            remainingSelectedFilters.includes(county)
            )
          ) {
            acc[filter] = true;
          } else {
            acc[filter] = false;
          }
        } else {
          acc[filter] = false;
        }
      } else if (
        countiesDividedByRegion[
          `${filterCategory === 'county' ? filter.toLowerCase() : target.name.toLowerCase()}`
        ].includes(filterCategory === 'county' ? target.name : filter) ||
        previouslySelectedFilterCategories.includes(filter)
      ) {
        acc[filter] = true;
      } else {
        acc[filter] = false;
      }
    } else {
      acc[filter] = false;
    }
    return acc;
  }, {});
};
export default getCorrespondingFilterCategoryState;
