import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import { PhoneAndEmailTab } from 'components';
import styled, { css, keyframes } from 'styled-components';
import { InView } from 'react-intersection-observer';
import ScrollDownIconSVG from 'images/shared/misc/scroll_down_icon.svg';
import TextContainer from './TextContainer';

class Hero extends PureComponent {
  state = {
    hideScrollDownIcon: false
  };

  handleScrollDownIcon = (userIsAtTopOfPage) =>
    this.setState((prevState) => ({
      hideScrollDownIcon: !userIsAtTopOfPage && !prevState.hideScrollDownIcon
    }));

  handleScrollEvent = (userHasScrolled) => {
    this.props.handleHeader(userHasScrolled);
    this.handleScrollDownIcon(userHasScrolled);
  };

  render() {
    const {
      buttonHeight,
      buttonLinkPath,
      buttonText,
      buttonWidth,
      children,
      customSubTextMaxWidth,
      date,
      fees,
      handleClick,
      themes,
      title,
      h2Text,
      handleHeader,
      hideScrollwDownIcon,
      pathname,
      subText,
      viewportWidth
    } = this.props;

    const childrenExtended = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        handleHeader
      })
    );

    const { hideScrollDownIcon } = this.state;
    return (
      <Wrapper>
        <InView onChange={this.handleScrollEvent} threshold={0.9}>
          {childrenExtended}
        </InView>
        <Underlay>
          <TextContainer
            title={title}
            h2Text={h2Text}
            subText={subText}
            customSubTextMaxWidth={customSubTextMaxWidth}
            buttonLinkPath={buttonLinkPath}
            handleClick={handleClick}
            buttonText={buttonText}
            buttonWidth={buttonWidth}
            buttonHeight={buttonHeight}
            date={date}
            themes={themes}
            pathname={pathname}
            fees={fees}
          />
        </Underlay>
        {viewportWidth >= 900 && <PhoneAndEmailTab />}
        {/* {!hideScrollwDownIcon && <ScrollDownIcon src={ScrollDownIconSVG} hideScrollDownIcon={hideScrollDownIcon} alt="" />} */}
      </Wrapper>
    );
  }
}

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Hero);

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

const Underlay = styled.div`
  background-blend-mode: multiply;
  background: rgba(000, 000, 000, 0.45);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const bounce = keyframes`
  0%, 100% {
    transform: translate3d(-50%, 0, 0);
  }

  50% {
    transform: translate3d(-50%, -25px, 0);
  }
`;

const ScrollDownIcon = styled.img`
  animation: ${bounce} 0.725s ease-in-out infinite;
  width: 25px;
  height: 55px;
  position: absolute;
  bottom: 3vh;
  margin-left: 50%;
  transform: translate3d(-50%, 0, 0);
  z-index: 2;

  ${({ hideScrollDownIcon }) =>
    hideScrollDownIcon
      ? css`
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        `
      : css`
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        `};
`;
