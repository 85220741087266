import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import { scrollToAnchor } from 'utils';

class PrivacyPolicyText extends PureComponent {
  handleScrollEvent = (userHasScrolled) => {
    this.props.handleHeader(userHasScrolled);
  };

  render() {
    return (
      <InView onChange={this.handleScrollEvent} threshold={1}>
        <Wrapper>
          <h1>Dynamic Events Privacy Policy</h1>
          <p style={{ textAlign: 'center' }}>This statement discloses the privacy practices for this website.</p>
          <br />
          <InnerWrapper>
            <b>Introduction</b>
            <p>
              We are committed to ensuring that your privacy is protected and understand the need
              for appropriate protection of all personal information provided by you to us. This
              Privacy Policy has been created in order that you understand the importance that we
              attach to this issue and our commitment to ensure that we comply with legislation in
              this area. 
            </p>
            <p>
              By providing us with your personal data, you consent, agree and accept that we, as
              well as our respective representatives and/or agents may collect, use, disclose and
              share among ourselves your personal data as described in this policy.
            </p>
            <LinksList>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('1', { offset: -103 })}>
                  Who We Are
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('2', { offset: -103 })}>
                  What Information Do We Collect About You?
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('3', { offset: -103 })}>
                  How Will We Use The Information About You?
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('4', { offset: -103 })}>
                  Data Sharing
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('5', { offset: -103 })}>
                  Transaction And Information Security
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('6', { offset: -103 })}>
                  Marketing
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('7', { offset: -103 })}>
                  Cookie Technology
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('8', { offset: -103 })}>
                  Business Transfer
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('9', { offset: -103 })}>
                  Accessing And Amending Your Personal Information
                </a>
              </li>
              <li>
                <a tabIndex={0} onClick={() => scrollToAnchor('10', { offset: -103 })}>
                  Retention Of Infomation
                </a>
              </li>
            </LinksList>
            <DetailedList>
              <li>
                <div>
                  <h4 id="1">Who We Are</h4>
                  <p>Dynamic Events</p>
                  <p>Company Registration No : 437526</p>
                </div>
              </li>
              <li>
                <div>
                  <h4 id="2">What Information Do We Collect About You?</h4>
                  <p>
                    We collect information about you when you contact us via our ‘Quick Quote’
                    section or via our ‘Contact Us’. Personal information will also be collected
                    when you book an event with us. We will need to collect this data to ensure we
                    can communicate with you in regards to planning your chosen activity / event. We
                    may collect this information about you and your booking over the phone or via
                    electronic form.
                  </p>
                  <p>
                    We may also collect information from you at networking and industry events to
                    subscribe to any of our marketing communications. Some of the information we
                    collect may be classed as personal data, that is, it is information about an
                    individual who can be identified from it.  It may be collected any time you
                    submit it to us, whatever the reason may be.
                  </p>
                  <p>In doing business with you, typically we will collect:</p>
                  <ul style={{ paddingInlineStart: '1em' }}>
                    <li>Full Name, Company, Email Address, Telephone number </li>
                    <li>
                      General information about your group – Nationality of group, Age Group, Number
                      of people in the group.
                    </li>
                    <li>
                      We collect payment card information from you should you choose to use this
                      form of payment for purchasing or guaranteeing use of our products and
                      services.
                    </li>
                  </ul>
                  <br />
                  <p>
                    If you provide us with any personal data relating to any third party (e.g.
                    information about your spouse, children, employees or colleagues) for particular
                    purposes, by submitting such information to us, you warrant and represent to us
                    that you have obtained the consent of such third party to provide us with their
                    personal data for the respective purposes.
                  </p>
                  <br />
                </div>
              </li>
              <li>
                <div>
                  <h4 id="3">How Will We Use The Information About You?</h4>
                  <p>
                    We use the information we collect about you to process your bookings, answer
                    your queries, and provide information to our event partners regarding your
                    booking and processing of your event.
                  </p>
                  <p>
                    With your consent, we will contact you via our marketing and sales channels
                    (email/ phone/ social media platform about other related products and services
                    we think may be of interest to you.  Our marketing communications are generally
                    sent by email but we may sometimes use other methods of delivery such as social
                    media or SMS.
                  </p>
                  <p>
                    We may use your information to meet and comply with any applicable rules, laws,
                    regulations, codes of practice or guidelines issued by any legal or regulatory
                    body which are binding on us; and for purposes which are reasonably related to
                    the aforesaid.
                  </p>
                  <p>
                    We sometimes engage the services of trusted third parties to process the
                    information collected by generating anonymised statistics to assist us with our
                    marketing campaigns and business analysis.  We do not disclose this anonymised
                    data outside of our business group.  It is not possible for the business to
                    identify an individual from such anonymised data presented in our internal
                    reports.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h4 id="4">Data Sharing</h4>
                  <p>
                    We share your data with members of the Dynamic Events team for booking
                    purposes. Where we use contracted and trusted third parties to facilitate our
                    provision of services and offers, we will also share your data with those
                    parties for that purpose. This includes the processing and delivery of marketing
                    communications to you. These companies include but are not limited to
                    Salesforce, Paradot Facebook.
                  </p>
                  <p>
                    Third parties are subject to confidentiality obligations and may only use your
                    personal data to perform the necessary functions as directed by us and not for
                    other purposes.
                  </p>
                  <p>
                    We do not share your data with any third parties outside of the above processing
                    arrangements and we do not share your data with any business external to our
                    group for their own marketing purposes.  From the data we collect, you should
                    only ever receive marketing communications from Dynamic Events.
                  </p>
                  <p>
                    We may also disclose personal data as permitted or required by law.  For
                    instance, if asked by the authorities, such as the <b>An Garda Síochána</b> or
                    other Government Agencies. We may share your personal data with them for the
                    purposes of prevention and detection of crime and in legal compliance with data
                    protection laws and the General Data Protection Regulations. Information is not
                    shared with them outside of this purpose.
                  </p>
                </div>
              </li>
              <br />
              <li>
                <div>
                  <h4 id="5">Transaction And Information Security</h4>
                  <p>
                    We understand how important it is to securely store any information that you
                    provide.  Dynamic Events take the privacy and security of your payment and
                    personal details very seriously.  As part of our security measures, we use
                    encryption technologies for transactions processed within our company.
                  </p>
                  <p>
                    You can tell whether a page is secure as ‘https’ will replace the ‘http’ at the
                    front of the www.dynamicevents.ie  in your browser address window. A small
                    locked padlock will also appear either in the bottom bar of your browser window
                    or alongside the browser address, depending upon which browser software and
                    version you are using.
                  </p>
                  <p>
                    Whilst we take reasonable, appropriate technical and organisational measures to
                    safeguard the personal data that you provide to us, no transmission over the
                    internet can ever be totally guaranteed secure.  Consequently, please be aware
                    that we cannot guarantee the complete security of any personal data that you
                    transfer over the internet to us whilst in transit. Sending such information is
                    entirely at your own risk.
                  </p>
                  <p>
                    We use Global Payments Inc. for our online and credit card payment which is
                    secure and GDPR compliant, please see the following link for details;
                    <a
                      href="https://www.globalpaymentsinc.com/en-ie/accept-payments/ecommerce/gdpr"
                      target="_blank">
                      https://www.globalpaymentsinc.com/en-ie/accept-payments/ecommerce/gdpr
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h4 id="6">Marketing</h4>
                  <p>
                    We work continually to ensure our marketing communication to you is relevant and
                    appropriate to your needs and likes. You have a right at any time to stop us
                    from contacting you for marketing purposes.  If you no longer wish to be
                    contacted, you can unsubscribe by any of the following methods:
                  </p>
                  <ul style={{ paddingInlineStart: '1em' }}>
                    <li>
                      selecting the UNSUBSCRIBE link included in our emails or on our website;
                    </li>
                    <li>
                      contacting our Marketing Team via the email addresses: <b>info@dynamicevents.ie</b>
                    </li>
                  </ul>
                  <br />
                </div>
              </li>
              <li>
                <div>
                  <h4 id="7">Cookie Technology</h4>
                  <p>
                    We use technologies, such as cookies, to customise content and advertising, to
                    provide social media features and to analyse traffic to the site. We also share
                    information about your use of our site with our trusted social media,
                    advertising and analytics partners. This information can include your IP
                    address, browser type, domain names, internet service provider (ISP), the files
                    viewed on our site (e.g., HTML pages, graphics, etc.), operating system,
                    clickstream data, access times and referring website addresses.
                  </p>
                  <p>
                    Cookies are not harmful to your computer, and they cannot contain viruses.
                    Cookies have a life span of six months, but if you want to delete them before
                    they expire, you can via the settings in your browser. We use the information we
                    collect from cookies to learn more about our website visitors through Google
                    Analytics; such as finding out where our traffic comes from, which pages are
                    most interesting, how many visitors and how visitors use our site. Google
                    Analytics collects no personal data such as name or email address. Read more
                    about how Google Analytics collects information here: 
                    <a href="https://policies.google.com/privacy" target="_blank">
                      https://policies.google.com/privacy
                    </a>
                  </p>
                  <p>
                    You have the right to decide whether to accept or reject cookies. You can
                    exercise your cookie preferences by clicking on the appropriate opt-out links
                    provided.
                  </p>
                  <p>
                    You can set or amend your web browser controls to accept or refuse cookies. If
                    you choose to reject cookies, you may still use our website, though your access
                    to some functionality and areas of our website may be restricted. As the means
                    by which you can refuse cookies through your web browser controls vary from
                    browser-to-browser, you should visit your browser’s help menu for more
                    information.
                  </p>
                  <p>
                    In addition, most advertising networks offer you a way to opt out of targeted
                    advertising based on cookies.  If you would like to find out more information,
                    please visit 
                    <a href="http://www.aboutads.info/choices/" target="_blank">
                      http://www.aboutads.info/choices/
                    </a> or 
                    <a href="http://www.youronlinechoices.com" target="_blank">
                      http://www.youronlinechoices.com
                    </a>
                    . You may opt out by clicking here: 
                    <a href="http://www.youronlinechoices.eu/" target="_blank">
                      http://www.youronlinechoices.eu/
                    </a>
                    . Please note this does not opt you out of being served advertising. You will
                    continue to receive generic advertisements.
                  </p>
                  <p>
                    We also use storage technologies like Salesforce Pardot and Google remarketing
                    technology. This tool allows us to understand and deliver ads that are more
                    relevant to you. The collected data remains anonymous and we cannot see the
                    personal data of any individual user. This information may include the content
                    you view, the date and time that you viewed this content, the products you
                    purchase, or your location information associated with your IP address. You can
                    opt out of this type of advertising in your browser and cookie settings or by
                    notifying us directly.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h4 id="8">Business Transfer</h4>
                  <p>
                    In the event that our business is transferred, sold or integrated with another
                    business, your details may be disclosed to our advisers and any prospective
                    purchaser’s advisers and may be passed to the new owners of the business.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <h4 id="9">Accessing And Amending Your Personal Information</h4>
                  <p>
                    You have a right to access a copy of the information which we hold about you. If
                    you would like to do this, please 
                    <a href="mailto:info@dynamicevents.ie" target="_blank">
                      email
                    </a>
                     or write to us at the following address.  We will process your request in line
                    with the guidelines provided by the Data Commissioner of Ireland <a href="https://www.dataprotection.ie" target="_blank">https://www.dataprotection.ie</a> and GDPR.
                  </p>
                  <p>Data Controller</p>
                  <p>Unit 8</p>
                  <p>Burgage House</p>
                  <p>Blessington</p>
                  <p>Co Wicklow</p>
                  <p>Ireland</p>
                  <br />
                  <p>We want to make sure that your personal information is accurate and up to date. You are able to make amendments, or withdraw your consent for use, by contacting us at <a href="mailto: info@dynamicevents.ie">info@dynamicevents.ie</a>, writing to us at Dynamic Events, Unit 8, Burgage House, Blessington, Co Wicklow, Ireland or my contacting us on tel: +353 (0) 45 891 100</p>
                </div>
              </li>
              <li>
                <div>
                  <h4 id="10">Retention Of Infomation</h4>
                  <p>Your personal data will be retained for as long as it is necessary to fulfil the purpose for which it is collected or for business or legal purposes, or in accordance with applicable laws.</p>
                  <p>Will we continue to send you relevant marketing information unless you unsubscribe via email or contact us directly.</p>
                </div>
              </li>
              <br />
            </DetailedList>
          </InnerWrapper>
        </Wrapper>
      </InView>
    );
  }
}

const Wrapper = styled.div`
  padding: 6em 2em 0;

  @media screen and (min-width: 360px) {
    padding: 7em 2em 0;
  }

  @media screen and (min-width: 375px) {
    padding: 8em 2em 0;
  }

  @media screen and (min-width: 967px) {
    padding: 9.25em 2em 0;
  }

  a {
    color: rgb(74, 79, 84);
    outline: none;
  }

  p {
    margin-bottom: 1em;
  }

  h1 {
    text-align: center;
    margin: 0 auto 1em;
  }

  li {
    font-size: 1rem;
    margin-left: 1em;
  }

  ol ul {
    margin-left: 2em;
    list-style-type: disc;
  }

  table {
    margin: 1.25em auto;
  }

  th {
    font-weight: normal;
  }

  td,
  th {
    border: 1px solid black;
    padding: 20px;
  }
`;

const InnerWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

const LinksList = styled.ol`
  margin-bottom: 1.5em;
  padding-inline-start: 1.5em;

  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const DetailedList = styled.ol`
  padding-left: 0;

  div {
    margin-left: 0.75em;
  }

  h4 {
    margin-bottom: 0.5em;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(PrivacyPolicyText);
