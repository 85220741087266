import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default sizes(({ width }) => ({ viewportWidth: width && width }))(({ heroImg }) => {
  return (
    <HeroImg
      image={heroImg.src ? getImage(heroImg.src) : getImage(heroImg)}
      alt={heroImg.alt ? heroImg.alt : heroImg.name.split('_').join(' ')}
    />
  );
});

const HeroImg = styled(GatsbyImage)`
  width: 100%;
  height: 100vh;
`;
