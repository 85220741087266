import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Modal from 'react-modal';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import sizes from 'react-sizes';
import { useLocation } from '@reach/router';
import purpleAndBlueModalBlob from 'images/shared/misc/blobs/purple_and_blue_modal_blob.svg';
import blueAndYellowModalBlob from 'images/shared/misc/blobs/blue_and_yellow_modal_blob.svg';
import CloseModalWhite from 'images/shared/misc/close_modal_white.svg';
import { Button, LoadingSpinner } from 'components';

const customModalStyles = (viewportWidth) => {
  return {
    overlay: {
      zIndex: viewportWidth < 780 ? 10000000 : 6
    },
    content: {
      border: 'none',
      borderRadius: '4px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      overflow: 'hidden'
    }
  };
};

class EventManagemmentTrendsPopup extends PureComponent {
  state = {
    trendsModalIsOpen: false,
    showLoadingSpinner: false
  };

  openTrendsModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        trendsModalIsOpen: true
      };
    });
  };

  componentDidMount = () => {
    const eventManagementBannerEC = document.querySelector('.event-management-banner-ec');
    if (eventManagementBannerEC) {
      eventManagementBannerEC.addEventListener('click', this.openTrendsModal);
      return () => eventManagementBannerEC.removeEventListener('click', this.openTrendsModal);
    }
  };

  closeTrendsModal = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        trendsModalIsOpen: false
      };
    });
  };

  handleEmailInputChange = (e) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        email: e.target.value
      };
    });
  };

  showLoadingSpinner = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showLoadingSpinner: true
      };
    });
  };

  render() {
    const { viewportWidth } = this.props;

    const { email, showLoadingSpinner, trendsModalIsOpen } = this.state;

    return (
      <Modal
        isOpen={trendsModalIsOpen}
        onRequestClose={this.closeTrendsModal}
        style={customModalStyles(viewportWidth)}
        closeTimeoutMS={500}
        contentLabel="Modal">
        <ModalInnerWrapper>
          <CloseModal onClick={this.closeTrendsModal} src={CloseModalWhite} />
          <StaticQuery
            query={graphql`
              {
                modalImg: file(relativePath: { eq: "shared/misc/event-conference.jpg" }) {
                  childImageSharp {
                    gatsbyImageData(width: 620)
                  }
                }
              }
            `}
            render={({ modalImg }) => (
              <ModalImgWrapper>
                <ModalImg image={getImage(modalImg)} />
                <img src={blueAndYellowModalBlob} alt="coloured red and yellow blob" />
                <img src={purpleAndBlueModalBlob} alt="coloured purple and blue blob" />
              </ModalImgWrapper>
            )}
          />
          <ModalText>
            <h2>
              Top 5<br />
              Event Management
              <br />
              Trends For 2025
            </h2>
            <form
              action="https://www2.dynamicevents.ie/l/405332/2024-10-29/23jfch1"
              method="post"
              enctype="application/x-www-form-urlencoded"
              onSubmit={() => {
                this.showLoadingSpinner();
              }}>
              <InputAndButtonWrapper>
                <div hidden>
                  <label htmlFor="pardot_extra_field">
                    Don't fill this out:
                    <input type="text" id="pardot_extra_field" name="pardot_extra_field" />
                  </label>
                </div>
                <input
                  autoFocus
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => this.handleEmailInputChange(e)}
                  required
                  placeholder={
                    viewportWidth < 620
                      ? 'Enter Your Work Email Address For Instant Access'
                      : 'Enter Your Work Email Address Here For Instant Access'
                  }
                />
                <Button
                  width="120px"
                  fontColor="#4F4F4F"
                  type="submit"
                  buttonColor="--dynamic-yellow">
                  {showLoadingSpinner ? (
                    <LoadingSpinner style={{ width: '1.5rem', color: '#fff' }} />
                  ) : (
                    'SUBMIT'
                  )}
                </Button>
              </InputAndButtonWrapper>
            </form>
            <p>
              <small>
                By submitting your work email you agree to be added to our mailing list.
              </small>
            </p>
          </ModalText>
        </ModalInnerWrapper>
      </Modal>
    );
  }
}

const InputAndButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  > input {
    font-family: montserrat, sans-serif;
    height: 40px;
    outline-color: var(--dynamic-blue);
    padding: 0 0.75rem;
    width: 396px;
  }
  p {
    position: absolute;
    bottom: -1rem;
    left: 0;
  }
  @media (min-width: 620px) {
    flex-direction: row;
    > input {
      width: 434px;
    }
  }
`;

const ModalImgWrapper = styled.div`
  position: relative;
  width: 100%;
  > img {
    position: absolute;
    &:first-of-type {
      top: -5px;
      right: 0px;
      width: 120px;
    }
    &:last-of-type {
      bottom: 0;
      left: -10px;
      width: 150px;
    }
    @media screen and (min-width: 620px) {
      &:first-of-type {
        top: -15px;
        width: 210px;
      }
      &:last-of-type {
        width: 200px;
      }
    }
    @media screen and (min-width: 900px) {
      width: 100%;
      &:first-of-type {
        top: 0px;
        width: unset;
      }
      &:last-of-type {
        left: -20px;
        width: unset;
      }
    }
  }
`;

const CloseModal = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  z-index: 1;
  cursor: pointer;
  @media screen and (min-width: 900px) {
    top: 20px;
    right: 20px;
  }
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background: #eeeeee;
  max-width: 620px;
  max-height: auto;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  @media screen and (min-width: 620px) {
    height: 570px;
  }
`;

const ModalImg = styled(GatsbyImage)`
  max-width: 620px;
  max-height: 300px;
`;

const ModalText = styled.div`
  text-align: center;
  padding: 1.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 620px) {
    padding: 1em 1.625em;
  }
  h2 {
    color: var(--dynamic-blue);
    font-family: proxima-nova, sans-serif;
    font-size: 1.65rem;
    margin: 0.1em auto 0.25em;
    text-transform: uppercase;
    font-weight: 900;

    @media screen and (min-width: 360px) {
      font-size: 1.75rem;
      margin: 0.1em auto 0.375em;
    }
    @media screen and (min-width: 620px) {
      font-size: 2.5rem;
    }
  }
  h3,
  ul {
    color: #000;
    font-family: montserrat, sans-serif;
  }
  h3 {
    color: var(--dynamic-red);
    font-family: adelle, serif;
    font-size: 1.125rem;
    text-transform: capitalize;
    @media screen and (min-width: 620px) {
      font-size: 1.25rem;
    }
  }
  ul {
    font-size: 0.95rem;
    line-height: 30px;
    letter-spacing: 0.01em;
    max-width: 720px;
    font-weight: 500;
    margin: 1em 0 1.325em;
    text-align: left;
    li::marker {
      color: var(--dynamic-red);
    }
    @media screen and (min-width: 620px) {
      font-size: 1rem;
    }
  }
  p {
    font-size: 0.8rem;
    margin: 1.2em auto 0;
    @media screen and (min-width: 620px) {
      margin: 0.9em auto 0;
    }
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  EventManagemmentTrendsPopup
);
