import React from 'react';
import sizes from 'react-sizes';
import { NavLinks, DynamicEventsLogo, SocialMediaLinks, PhoneAndEmailTab } from 'components';
import styled, { css } from 'styled-components';
import HamburgerMenuIconSVG from 'images/shared/header/HamburgerMenuIcon';
import CloseHamburgerMenuIconSVG from 'images/shared/header/CloseHamburgerMenuIcon';

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  ({ hamBurgerMenuIsOpen, toggleHamburgerMenu }) => (
    <>
      <StyledHamburgerMenuIcon alt="Hamburger Menu Icon" onClick={(e) => toggleHamburgerMenu(e)} />
      <HamburgerMenu hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}>
        <DynamicEventsLogo onMobile hamBurgerMenuIsOpen={hamBurgerMenuIsOpen} />
        <StyledCloseHamburgerMenuIcon onClick={(e) => toggleHamburgerMenu(e)} />
        <MobileNavLinksWrapper>
          <NavLinks
            toggleHamburgerMenu={toggleHamburgerMenu}
            hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}
          />
        </MobileNavLinksWrapper>
        <SocialMediaLinks inHamburgerMenu />
        <PhoneAndEmailTab onMobile />
      </HamburgerMenu>
    </>
  )
);

const StyledHamburgerMenuIcon = styled(HamburgerMenuIconSVG)`
  position: fixed;
  width: 40px;
  height: 40px;
  right: 2em;
  top: 10px;
  cursor: pointer;
  z-index: 4;

  @media screen and (min-width: 375px) {
    top: 16px;
  }

  @media screen and (min-width: 967px) {
    top: 26px;
  }

  &:hover {
    rect {
      fill: var(--dynamic-dark-blue);
    }
  }
`;

const StyledCloseHamburgerMenuIcon = styled(CloseHamburgerMenuIconSVG)`
  top: 16px;
  right: 24px;
  position: absolute;
  cursor: pointer;
`;

const HamburgerMenu = styled.div`
  background: var(--dynamic-blue);
  position: fixed;
  transform: translateX(-100vw);
  transition: transform 0.5s ease-in-out;
  height: 100vh;
  width: 100%;
  z-index: 4;

  ${({ hamBurgerMenuIsOpen }) =>
    hamBurgerMenuIsOpen &&
    css`
      transform: translateX(0);
      transition: transform 0.5s ease-in-out;
    `}
`;

const MobileNavLinksWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-height: 57.1vh;
  height: 100%;
  outline: none;
  position: relative;
  top: 20vh;

  @media screen and (min-width: 375px) {
    top: 20%;
    max-height: 365px;
  }

  button {
    position: relative;
    top: 1.2em;

    span {
      font-size: 1rem;
    }
  }
`;
