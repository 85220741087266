import React from 'react';
import sizes from 'react-sizes';
import ArrowSVG from 'images/shared/misc/left_arrow.svg';
import styled, { css } from 'styled-components';

/* TODO: Use my re-usable button component here */
/* TODO: Use my re-usable button component for all other carousels */
const SmallCustomCarouselButtons = ({
  goToPreviousSlide,
  goToNextSlide,
  disableLeftArrow,
  disableRightArrow,
  leftArrowOffset,
  rightArrowOffset,
  service
}) => {
  return (
    <>
      <StyledCustomLeftArrow
        onClick={() => goToPreviousSlide(service)}
        disabled={disableLeftArrow}
        leftArrowOffset={leftArrowOffset}>
        <img src={ArrowSVG} alt="" style={{ marginRight: '5px' }} />
      </StyledCustomLeftArrow>
      <StyledCustomRightArrow
        onClick={() => goToNextSlide(service)}
        disabled={disableRightArrow}
        rightArrowOffset={rightArrowOffset}>
        <img src={ArrowSVG} alt="" style={{ marginLeft: '5px', transform: 'rotate(180deg)' }} />
      </StyledCustomRightArrow>
    </>
  );
};

const StyledCustomArrow = styled.div`
  align-items: center;
  background-color: var(--dynamic-blue);
  border-radius: 10px;
  top: calc(100% - 20px);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute !important;
  width: 40px;
  z-index: 2;

  &:hover {
    background-color: var(--dynamic-dark-blue);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: scale3d(1.2, 1.2, 1.2);
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
  }

  &:not(:hover) {
    background-color: var(--dynamic-blue);
    box-shadow: none;
    transform: scale3d(1, 1, 1);
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
  }

  &:active {
    background-color: var(--dynamic-dark-grey);
    transition: background-color 0.025s ease-in-out;
  }
`;

const StyledCustomLeftArrow = styled(StyledCustomArrow)`
  ${({ leftArrowOffset }) =>
    css`
      left: ${leftArrowOffset};
      transform-origin: center right;
    `};

  ${({ disabled }) =>
    disabled
      ? css`
          &,
          &:hover {
            background-color: rgb(189, 189, 189) !important;
            box-shadow: none;
            transform: scale3d(1, 1, 1);
            transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
            pointer-events: none;
          }
        `
      : css`
          background-color: var(--dynamic-blue) !important;
        `}
`;

const StyledCustomRightArrow = styled(StyledCustomArrow)`
  ${({ rightArrowOffset }) =>
    css`
      right: ${rightArrowOffset};
      transform-origin: center left;
    `};

  ${({ disabled }) =>
    disabled
      ? css`
          &,
          &:hover {
            background-color: rgb(189, 189, 189) !important;
            box-shadow: none;
            transform: scale3d(1, 1, 1);
            transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
            pointer-events: none;
          }
        `
      : css`
          background-color: var(--dynamic-blue) !important;
        `}
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  SmallCustomCarouselButtons
);
