import React from 'react';
import styled from 'styled-components';
import { Markup } from 'interweave';

const CardsIntro = ({ cardsIntro, toHTML }) => {
  return (
    <Container>
      <h2>{cardsIntro.h2}</h2>
      <Markup content={toHTML(cardsIntro.paragraph)} noWrap />
    </Container>
  );
};

const Container = styled.div`
  max-width: 775px;
  margin: 0 auto 3rem;

  h2 {
    color: var(--dynamic-blue);
    font-family: adelle, serif;
    font-size: 1.9rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5em;

    @media screen and (min-width: 1143px) {
      font-size: 2.5rem;
    }
  }

  p {
    color: var(--dynamic-dark-grey);
    font-family: 'proxima-nova', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;
    margin-bottom: 2.5em;
    text-align: center;

    @media screen and (min-width: 1200px) {
      font-size: 1.3rem;
      margin-bottom: 4rem;
    }
  }
`;

export default CardsIntro;
