import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { MultiCarousel, SectionTitle } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import QuotationMarksSVG from 'images/shared/misc/quotation_marks.svg';
import BlobPurpleYellow from 'images/home/client_testimonials/blob_purple_yellow.svg';

const ClientTestimonials = ({
  pathname,
  genericTestimonials,
  specificTestimonials,
  viewportWidth,
  paddingStyle
}) => {
  const testimonials =
    specificTestimonials && specificTestimonials.length
      ? specificTestimonials
      : genericTestimonials;
  return (
    testimonials && (
      <Wrapper paddingStyle={paddingStyle}>
        <SectionTitle title="Client Testimonials" paddingStyle="0 0 1.1em" />
        {pathname === '/' && viewportWidth > 550 && (
          <StyledBlobPurpleYellow src={BlobPurpleYellow} alt="purple and yellow blob" />
        )}
        {testimonials && (
          <MultiCarousel
            autoPlaySpeed={4000}
            leftArrowOffset={
              viewportWidth >= 1258
                ? '-1.1%'
                : viewportWidth >= 972
                ? '-6.75%'
                : viewportWidth >= 690
                ? '-10%'
                : 'calc(50% - 47.5px)'
            }
            rightArrowOffset={
              viewportWidth >= 1258
                ? 'calc(-1.1% + 6px)'
                : viewportWidth >= 972
                ? 'calc(-6.75% + 9px)'
                : viewportWidth >= 690
                ? 'calc(-10% + 8px)'
                : 'calc(50% - 47.5px)'
            }
            responsiveRules={{
              desktop: {
                breakpoint: { max: 10000, min: 1258 },
                items: 3
              },
              table: {
                breakpoint: { max: 1257, min: 972 },
                items: 2
              },
              mobile: {
                breakpoint: { max: 971, min: 0 },
                items: 1
              }
            }}>
            {testimonials.map((testimonial, i) => {
              const { personsName, title: companyName, companyLogo, quote } = testimonial;
              return (
                <TestimonialWrapper key={i}>
                  <TestimonialHeader>
                    <NameAndCompanyWrapper>
                      <Name>{personsName}</Name>
                      <Company>{companyName}</Company>
                    </NameAndCompanyWrapper>
                    {companyLogo && companyLogo.childImageSharp && (
                      <GatsbyImage image={getImage(companyLogo)} alt={`${companyName} logo`} />
                    )}
                  </TestimonialHeader>
                  <TestimonialBody>
                    <QuotationMarks src={QuotationMarksSVG} alt="quotation marks" />
                    <Quote>{quote}</Quote>
                  </TestimonialBody>
                </TestimonialWrapper>
              );
            })}
          </MultiCarousel>
        )}
      </Wrapper>
    )
  );
};

const Wrapper = styled.section`
  position: relative;
  ${({ paddingStyle }) => paddingStyle && `padding: ${paddingStyle}`};

  @media screen and (min-width: 426px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const TestimonialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 545px;
  height: 100%;

  @media screen and (min-width: 972px) {
    max-width: 407.5px;
  }

  @media screen and (min-width: 1258px) {
    max-width: 360px;
  }

  p {
    font-size: 1rem;
  }
`;
const TestimonialHeader = styled.div`
  background-color: var(--dynamic-dark-grey);
  padding: 0.75em 0.75em 0.75em 1.125em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  min-height: 84px;
`;

const NameAndCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 258px;
`;

const Name = styled.p`
  color: #fff;
  font-weight: bold;
`;
const Company = styled.p`
  color: #fff;
  font-style: italic;
`;

const TestimonialBody = styled.div`
  background-color: var(--site-background);
  padding: 1.75em 1.188em;
  height: 100%;
  font-style: italic;
  min-height: 243px;
  border-radius: 0px 0px 10px 10px;
`;

const QuotationMarks = styled.img`
  margin-bottom: 15px;
`;

const Quote = styled.p`
  color: var(--dynamic-dark-grey);
  margin-left: 1.36em;
  line-height: 1.25em;
  font-family: 'proxima-nova', sans-serif;
`;

const StyledBlobPurpleYellow = styled.img`
  bottom: -179px;
  position: absolute;
  right: 0;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(ClientTestimonials);
