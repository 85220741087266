import * as React from 'react';
function SvgQuestionMark(props) {
  return (
    <svg width={6} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.063 4.686c0 .372.173.698.533.977l1.067-.326c-.201-.14-.346-.36-.346-.546 0-.489.534-.78 1.11-1.117C5.178 3.256 6 2.78 6 1.814 6 .791 4.918 0 3.13 0 1.788 0 .707.442 0 1.174l.837.745c.504-.535 1.168-.884 2.149-.884 1.038 0 1.528.442 1.528.953 0 .535-.533.826-1.11 1.152-.65.372-1.341.767-1.341 1.546zm.043 2.581c0 .396.404.733.894.733s.909-.337.909-.733c0-.395-.419-.72-.909-.72-.49 0-.894.325-.894.72z"
        fill="#fff"
      />
    </svg>
  );
}
export default SvgQuestionMark;
