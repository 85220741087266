import React, { PureComponent, createRef } from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button, SectionTitle, SingleCarousel, SmallCustomCarouselButtons } from 'components';
import { StaticQuery, graphql, Link } from 'gatsby';
import { InView } from 'react-intersection-observer';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_background_1.svg';
import LightBlueBackgroundMobileSVG from 'images/shared/misc/light_blue_background_4.svg';
import BlobRedPurpleBlueSVG from 'images/shared/misc/blobs/blob_red_purple_blue.svg';
import ArrowSVG from 'images/shared/misc/left_arrow.svg';
import { getSlug } from 'utils';

const ImgAndPrevNextButtons = ({
  handleSlideChange,
  carouselRef,
  goToPreviousSlide,
  goToNextSlide,
  title,
  popularTeamEvents,
  currentSlide,
  totalItems,
  viewportWidth
}) => (
  <ImgAndPrevNextButtonsWrapper>
    <ImgWrapper>
      <Link to={`/team-building/${getSlug(title)}/`}>
        <SingleCarousel
          carouselImgs={popularTeamEvents.edges.map(
            ({ node }) => node.frontmatter.featuredImg
          )}
          currentSlide={currentSlide}
          totalItems={totalItems}
          handleSlideChange={handleSlideChange}
          innerRef={carouselRef}
          showIndicators={viewportWidth >= 1100 && totalItems <= 10}
        />
      </Link>
    </ImgWrapper>
    {viewportWidth < 1100 && totalItems > 1 && (
      <SmallCustomCarouselButtons
        disableLeftArrow={currentSlide === 0}
        disableRightArrow={currentSlide === totalItems - 1}
        goToNextSlide={goToNextSlide}
        goToPreviousSlide={goToPreviousSlide}
        leftArrowOffset="calc(29.75% - 45.5px)"
        rightArrowOffset="calc(69% - 45.5px)"
      />
    )}
    {viewportWidth >= 1100 && (
      <PrevNextButtonWrapper>
        <Button
          handleClick={goToPreviousSlide}
          disabled={currentSlide === 0}
          width="150px"
          ariaLabel="previous event"
          text="Previous Event"
          borderRadius="20px 0 0 20px">
          <img src={ArrowSVG} alt="" style={{ marginRight: '5px' }} />
        </Button>
        <Button
          handleClick={goToNextSlide}
          disabled={currentSlide === totalItems - 1}
          width="150px"
          text="Next Event"
          ariaLabel="next event"
          borderRadius="0 20px 20px 0">
          <img src={ArrowSVG} alt="" style={{ marginLeft: '5px' }} />
        </Button>
      </PrevNextButtonWrapper>
    )}
  </ImgAndPrevNextButtonsWrapper>
);

class PopularTeamEvents extends PureComponent {
  state = {
    animateLightBlueBackgroundSVG: false,
    currentSlide: 0,
    totalItems: undefined
  };

  carouselRef = createRef();

  componentDidMount() {
    this.setCarouselTotalItems();
  }

  setCarouselTotalItems = () => {
    this.setState((prevState) => ({
      ...prevState,
      totalItems: this.carouselRef.current.itemsRef.length
    }));
  };

  goToPreviousSlide = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentSlide: prevState.currentSlide - 1
    }));
  };

  goToNextSlide = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentSlide: prevState.currentSlide + 1
    }));
  };

  handleSlideChange = (currentSlide) => {
    this.setState((prevState) => {
      if (currentSlide !== prevState.currentSlide) {
        return {
          ...prevState,
          currentSlide
        };
      }
    });
  };

  handleSVG = (animateLightBlueBackgroundSVG) => this.setState({ animateLightBlueBackgroundSVG });

  render() {
    const { viewportWidth } = this.props;
    const { animateLightBlueBackgroundSVG, currentSlide, totalItems } = this.state;
    return (
      <Wrapper>
        <LightBlueBackground
          animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
          src={viewportWidth < 400 ? LightBlueBackgroundMobileSVG : LightBlueBackgroundSVG}
          alt="Light blue blackground"
          viewportWidth={viewportWidth}
          // animationDuration={`${viewportWidth / 1920}s`}
          animationDuration="0.75s"
        />
        <InView onChange={this.handleSVG} threshold={0.99} triggerOnce>
          <SectionTitle title="Popular Team Events" paddingStyle="2.5em 0 1.1em" />
        </InView>
        <ContentWrapper>
          {viewportWidth > 742 && (
            <StyledBlobRedPurpleBlue src={BlobRedPurpleBlueSVG} alt="red, purple, and blue blob" />
          )}
          <StaticQuery
            query={graphql`
              {
                popularTeamEvents: allMarkdownRemark(
                  filter: { frontmatter: { includeInPopularTeamEventsSection: { eq: true } } }
                ) {
                  edges {
                    node {
                      frontmatter {
                        title
                        summary
                        featuredImg {
                          alt
                          src {
                            childImageSharp {
                              gatsbyImageData(
                                width: 710
                                height: 710
                                transformOptions: { cropFocus: CENTER }
                                quality: 90
                                pngOptions: { compressionSpeed: 10 }
                                placeholder: TRACED_SVG
                                formats: [AUTO, WEBP]
                              )
                            }
                          }
                        }
                        themes
                        formatOfDeliveryAndEventSupport
                        approach
                        activityLocation
                      }
                    }
                  }
                }
              }
            `}
            render={({ popularTeamEvents }) => {
              const {
                title,
                summary,
                formatOfDeliveryAndEventSupport,
                approach,
                activityLocation
              } = popularTeamEvents.edges[currentSlide].node.frontmatter;
              return (
                popularTeamEvents && (
                  <>
                    {viewportWidth >= 1100 && (
                      <ImgAndPrevNextButtons
                        popularTeamEvents={popularTeamEvents}
                        carouselRef={this.carouselRef}
                        currentSlide={currentSlide}
                        goToNextSlide={this.goToNextSlide}
                        goToPreviousSlide={this.goToPreviousSlide}
                        handleSlideChange={this.handleSlideChange}
                        title={title}
                        totalItems={totalItems}
                        viewportWidth={viewportWidth}
                      />
                    )}
                    <InfoWrapper>
                      {formatOfDeliveryAndEventSupport && approach && activityLocation && (
                        <Tags>
                          <small>
                            {[
                              'Team Building',
                              approach,
                              formatOfDeliveryAndEventSupport,
                              activityLocation
                            ].join(' | ')}
                          </small>
                        </Tags>
                      )}
                      <ActivityTitle>{title}</ActivityTitle>
                      {viewportWidth < 1100 && (
                        <ImgAndPrevNextButtons
                          popularTeamEvents={popularTeamEvents}
                          carouselRef={this.carouselRef}
                          currentSlide={currentSlide}
                          goToNextSlide={this.goToNextSlide}
                          goToPreviousSlide={this.goToPreviousSlide}
                          handleSlideChange={this.handleSlideChange}
                          title={title}
                          totalItems={totalItems}
                          viewportWidth={viewportWidth}
                        />
                      )}
                      <ActivityInfo>
                        {summary}
                        <Link to={`/team-building/${getSlug(title)}/`}>
                          <Button
                            text="Explore Event"
                            ariaLabel="explore event"
                            buttonColor="--dynamic-blue"
                          />
                        </Link>
                      </ActivityInfo>
                    </InfoWrapper>
                  </>
                )
              );
            }}
          />
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin-top: 40px;
  position: relative;
`;

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
  max-width: 1200px;

  @media screen and (min-width: 1100px) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    margin: unset;
  }

  @media screen and (min-width: 1250px) {
    margin: 0 auto;
  }
`;

const StyledBlobRedPurpleBlue = styled.img`
  bottom: -200px;
  position: absolute;
  right: 0;
`;

const ImgAndPrevNextButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 710px;
  max-width: 710px;
  padding-right: 5.64%;
  position: relative;
  width: 100%;

  @media screen and (min-width: 500px) {
    padding-right: 0;
  }
`;

const ImgWrapper = styled.div`
  height: 100%;
  max-height: 710px;
  max-width: 710px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1100px) {
    margin-bottom: 1.5em;
  }
`;

const PrevNextButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 310px;

  button img {
    height: 15px;
    width: 15px;
  }

  button:nth-of-type(1) span {
    margin-left: 2px;
  }

  button:nth-of-type(2) span {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  button:hover:first-of-type img {
    transform: translateX(-4px);
    transition: transform 0.15s ease-in-out;
  }

  button:not(:hover):first-of-type img {
    transform: translate3d(0, 0, 0);
    transition: transform 0.15s ease-in-out;
  }

  button:hover:last-of-type img {
    transform: translateX(4px) rotate(180deg);
    transition: transform 0.15s ease-in-out;
  }

  button:not(:hover):last-of-type img {
    transform: translateX(0) rotate(180deg);
    transition: transform 0.15s ease-in-out;
  }
`;

const InfoWrapper = styled.div`
  height: 100%;
  max-width: 443px;
  width: 100%;
  position: relative;

  @media screen and (min-width: 1100px) {
    margin-left: 6.388%;
  }

  @media screen and (min-width: 1200px) {
    margin-left: 3.194%;
    position: relative;
    left: 3.194%;
  }

  a {
    text-decoration: none;
  }

  button:last-of-type {
    margin-top: 2em;
  }
`;

const Tags = styled.p`
  color: var(--dynamic-dark-grey);
  font-family: 'proxima-nova', sans-serif;
  font-size: 0.85rem;
  letter-spacing: 0.001em;
  margin-bottom: 0.95em;
  margin-left: 5.64%;

  @media screen and (min-width: 468px) {
    font-size: 1rem;
    margin-left: 0;
  }
`;

const ActivityTitle = styled.h3`
  font-family: adelle, serif;
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin-bottom: 1em;
  padding-left: 5.64%;

  @media screen and (min-width: 468px) {
    font-size: 2.875rem;
    margin-bottom: 0.625em;
    padding-left: 0;
  }

  @media screen and (min-width: 1100px) {
    margin-bottom: 1em;
  }
`;

const ActivityInfo = styled.p`
  font-family: 'proxima-nova', sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  margin-bottom: 1.7em;
  margin-top: 2.4em;
  padding: 0 5.64%;
  position: absolute;

  @media screen and (min-width: 468px) {
    font-size: 1.25rem;
    padding: 0;
  }

  @media screen and (min-width: 1100px) {
    margin-top: 0;
    max-width: 419px;
    padding-right: 0.5em;
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    padding-right: 0;
  }
`;

const LightBlueBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  top: ${({ viewportWidth }) => (viewportWidth < 400 ? '-161px' : '-68px')};
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(PopularTeamEvents);
