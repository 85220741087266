import React from 'react';
import sizes from 'react-sizes';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { getSlug } from 'utils';
import { format } from 'date-fns';
import { Button } from 'components';
import SmallRightArrow from 'images/shared/misc/SmallRightArrow';

const customSortArr = [
  'In Person and Fully Managed',
  'Fully Virtual',
  'In Person with Remote Host'
];

const Card = ({
  title,
  summary,
  featuredImg,
  graphic,
  formatOfDeliveryAndEventSupport,
  date,
  pathname
}) => {
  return (
    <StyledLink
      to={`${
        pathname === '/event-management/'
          ? '/event-management/'
          : pathname === '/our-work/' ||
            pathname.includes('/case-studies/') ||
            pathname.includes('/event-management/')
          ? '/case-studies/'
          : pathname.includes('/blog/')
          ? '/blog/'
          : pathname.includes('/venue-partners/')
          ? '/venue-partners/'
          : pathname.includes('/team-training/')
          ? '/team-training/'
          : '/team-building/'
      }${getSlug(title)}/`}
      style={{ textDecoration: 'none' }}>
      <Wrapper>
        <FeaturedImg
          image={featuredImg.src ? getImage(featuredImg.src) : getImage(featuredImg)}
          alt={featuredImg.alt}
        />
        <CardText>
          {(pathname.includes('/our-work/') || pathname.includes('/case-studies/')) &&
            graphic &&
            graphic.src !== null && (
              <Graphic image={getImage(graphic.src)} alt={`${title} graphic`} />
            )}
          {date && <CardDate>{format(new Date(date), 'MMM do, yyyy')}</CardDate>}
          {formatOfDeliveryAndEventSupport &&
            (pathname.includes('/team-building/') || pathname.includes('/team-training/')) && (
              <DeliveryAndSupportFormats>
                {formatOfDeliveryAndEventSupport
                  .sort((a, b) => customSortArr.indexOf(a) - customSortArr.indexOf(b))
                  .map((format) => (
                    <DeliveryAndSupportFormat>{format}</DeliveryAndSupportFormat>
                  ))}
              </DeliveryAndSupportFormats>
            )}
          <CardTitle formatOfDeliveryAndEventSupport={formatOfDeliveryAndEventSupport} date={date}>
            {title}
          </CardTitle>
          <CardSummary>
            {summary}
            <br />
            <br />
          </CardSummary>
        </CardText>
        <SeeMore>
          Read more <SmallRightArrow />
        </SeeMore>
      </Wrapper>
    </StyledLink>
  );
};

const FeaturedImg = styled(GatsbyImage)`
  border-radius: 10px 10px 0 0;
  width: 100% !important;
`;

const Graphic = styled(GatsbyImage)`
  border-radius: 10px;
  top: -50px;
  box-shadow: 2px 2px 7px rgba(189, 189, 189, 0.3);
  max-width: 106px;
  position: absolute !important;
  right: -2.86%;
  width: 30.3%;
`;

const SeeMore = styled.p`
  font-family: 'proxima-nova', sans-serif;
  bottom: 3.4%;
  color: #bdbdbd;
  font-size: 1.25rem;
  font-weight: 700;
  position: absolute;
  right: 5.25%;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledLink = styled(Link)`
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in-out forwards;
`;

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 7px rgba(189, 189, 189, 0.4);
  height: 100%;
  width: 100%;

  &:hover {
    box-shadow: 3px 3px 16px rgba(189, 189, 189, 0.8);
    transform: translate3d(0, -10px, 0);
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

    ${Graphic} {
      transform: scale3d(1.175, 1.175, 1.175);
      transform-origin: bottom right;
      transition: transform 0.2s ease-in-out;
    }

    ${SeeMore} {
      color: var(--dynamic-blue);
      transition: color 0.2s ease-in-out;

      svg path {
        stroke: var(--dynamic-blue);
        transition: stroke 0.2s ease-in-out;
      }
    }
  }

  &:not(:hover) {
    box-shadow: 3px 3px 7px rgba(189, 189, 189, 0.4);
    transform: translate3d(0, 0, 0);
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

    ${Graphic} {
      transform: scale3d(1, 1, 1);
      transform-origin: bottom right;
      transition: transform 0.2s ease-in-out;
    }

    ${SeeMore} {
      color: #bdbdbd;
      transition: color 0.2s ease-in-out;

      svg path {
        stroke: #bdbdbd;
        transition: stroke 0.2s ease-in-out;
      }
    }
  }
`;

const CardText = styled.div`
  position: relative;

  h3,
  p {
    letter-spacing: 0.01em;
  }

  p {
    font-family: 'proxima-nova', sans-serif;
  }
`;

const CardDate = styled.p`
  font-size: 0.875rem;
  color: var(--dynamic-blue);
  position: absolute;
  padding: 1.15em 5.75% 0;
`;

const DeliveryAndSupportFormats = styled.ul`
  list-style-type: none;
  padding: 6.25% 5.75% 0;
`;

const DeliveryAndSupportFormat = styled.li`
  background-color: var(--dynamic-blue);
  border-radius: 6px;
  color: #fff;
  display: inline-block;
  font-family: 'proxima-nova', sans-serif;
  font-size: 0.75rem;
  letter-spacing: 0.01em;
  padding: 0.35em 1.2em;
  margin-right: 0.45em;
  margin-bottom: 0.45em;
`;

const CardTitle = styled.h3`
  color: var(--dynamic-dark-grey);
  font-family: adelle, serif;
  font-size: 1.313rem;
  padding: ${({ formatOfDeliveryAndEventSupport, date }) =>
    formatOfDeliveryAndEventSupport ? '3.75% 5.75% 0' : date ? '13.75% 5.75% 0' : '8.5% 5.75% 0'};
`;

const CardSummary = styled.p`
  color: var(--dynamic-dark-grey);
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.7em;
  padding: 0 5.75% 8.5%;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Card);
