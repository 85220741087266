import * as React from 'react';
function SvgSearchIcon(props) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.428 8.143a3.571 3.571 0 100-7.143 3.571 3.571 0 000 7.143zM5.011 6.962L1 11"
        stroke="#4DB1E8"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgSearchIcon;