import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Cards } from 'components';
import { InView } from 'react-intersection-observer';
import SearchIcon from 'images/blog/search_icon.svg';
import BlobYellowRed from 'images/team_building/misc/blob_yellow_red.svg';
import BlobBluePurple from 'images/team_building/misc/blob_blue_purple.svg';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_background_3.svg';

class BlogFiltersAndCards extends PureComponent {
  state = {
    activeFilter: 'See All',
    animateLightBlueBackgroundSVG: false,
    searchQuery: ''
  };

  handleSearchInput = ({ target }) => {
    const { value } = target;
    this.setState((prevState) => ({
      searchQuery: value,
      activeFilter: value !== '' ? 'See All' : prevState.activeFilter
    }));
  };

  handleFilterClick = ({ target }) => {
    if (target.nodeName !== 'DIV') {
      this.setState({
        searchQuery: '',
        activeFilter: target.innerText
      });
    }
  };

  handleSVG = (animateLightBlueBackgroundSVG) => this.setState({ animateLightBlueBackgroundSVG });

  render() {
    const { pathname, viewportWidth } = this.props;
    const { animateLightBlueBackgroundSVG, activeFilter, searchQuery } = this.state;
    return (
      <Wrapper>
        <LightBlueBackground
          animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
          src={LightBlueBackgroundSVG}
          alt="Blue blob"
          // animationDuration={`${viewportWidth / 1920}s`}
          animationDuration="0.75s"
        />
        {viewportWidth >= 400 && (
          <StyledBlobYellowRed src={BlobYellowRed} alt="yellow and red blob" />
        )}
        {viewportWidth >= 400 && (
          <StyledBlobBluePurple src={BlobBluePurple} alt="blue and purple blob" />
        )}
        <InView onChange={this.handleSVG} threshold={0.99} triggerOnce>
          <Filters activeFilter={activeFilter} searchIcon={searchQuery === '' && SearchIcon}>
            <p>Use the filters to quickly browse through our various subjects</p>
            <div onClick={(e) => this.handleFilterClick(e)} role="button" tabIndex={0}>
              {/* TODO: Refactor this. This is too hacky. Think of a more DRY way to do this - maybe borrow some ideas from how I did the Promozoo site's 'Our Work' filter */}
              <Filter active={activeFilter === 'See All'}>See All</Filter>
              <Filter active={activeFilter === 'Online/Virtual Events'}>
                Online/Virtual Events
              </Filter>
              <Filter active={activeFilter === 'Ice Breaker'}>
                Ice Breaker
              </Filter>
              <Filter active={activeFilter === 'Remote Working'}>Remote Working</Filter>
              <Filter active={activeFilter === 'Team Building'}>Team Building</Filter>
              <Filter active={activeFilter === 'Team Training'}>Team Training</Filter>
              <Filter active={activeFilter === 'Event Management'}>Event Management</Filter>
              <Filter active={activeFilter === 'Wellbeing'}>Wellbeing</Filter>
              <Filter active={activeFilter === 'Learning & Development'}>
                Learning & Development
              </Filter>
              <Filter active={activeFilter === 'News'}>News</Filter>
            </div>
            <p>or search by typing in keywords</p>
            <div>
              <KeywordInput onChange={this.handleSearchInput} value={searchQuery} />
            </div>
          </Filters>
        </InView>
        <StaticQuery
          query={graphql`{
  blogPosts: allFile(
    filter: {sourceInstanceName: {eq: "cms_data_blog_posts"}, childMarkdownRemark: {frontmatter: {title: {ne: ""}}}, ext: {eq: ".md"}}
    sort: [{childMarkdownRemark: {frontmatter: {date: DESC}}}, {childMarkdownRemark: {frontmatter: {title: ASC}}}]
  ) {
    edges {
      node {
        id
        childMarkdownRemark {
          frontmatter {
            title
            summary
            themes
            date
            featuredImg {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    height: 200
                    transformOptions: {cropFocus: CENTER}
                    quality: 30
                    pngOptions: {compressionSpeed: 10}
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}`}
          render={({ blogPosts }) => {

            const allCards = blogPosts.edges;


            let filteredCards;

            if (searchQuery === '') {
              filteredCards = allCards.filter((card) =>
                card.node.childMarkdownRemark.frontmatter.themes.includes(activeFilter)
              );
            } else if (searchQuery !== '') {
              filteredCards = allCards.filter((card) =>
                card.node.childMarkdownRemark.frontmatter.title
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              );
            }



            const showTheseCards =
              activeFilter === 'See All' && searchQuery === ''
                ? allCards
                : filteredCards.length
                ? filteredCards
                : [];



            return blogPosts && <Cards showTheseCards={showTheseCards} pathname={pathname} />;
          }}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.section`
  padding: 0 6.181%;
  position: relative;

  @media screen and (min-width: 1260px) {
    padding: 0;
  }
`;

const Filters = styled.div`
  background-color: #fff;
  max-width: 1100px;
  box-shadow: 7px 7px 16px rgba(189, 189, 189, 0.4);
  border-radius: 10px;
  margin: 30px auto;
  padding: 1.5em;
  position: relative;

  @media screen and (min-width: 1200px) {
    margin: 54px auto;
  }

  p {
    color: var(--dynamic-dark-grey);
    font-family: 'proxima-nova', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;
    margin-bottom: 0.9em;
    text-align: center;
  }

  > div:first-of-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: space-between;
    max-width: 760px;
    margin: 0 auto 44px;
    outline: none;

    button {
      min-width: 112px;
      height: 40px;
      margin: 2px;
    }
  }

  > div:last-of-type {
    position: relative;
    max-width: 616px;
    width: 100%;
    margin: 0 auto;

    &:before {
      background: ${({ searchIcon }) => `url(${searchIcon}) center / contain no-repeat`};
      bottom: 23px;
      content: '';
      left: calc(50% - 63px);
      position: absolute;
      top: 0;
      width: 12px;
    }
  }
`;

const Filter = styled.button`
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--dynamic-blue);
  color: var(--dynamic-blue);
  cursor: pointer;
  font-family: 'proxima-nova', sans-serif;
  font-size: 1rem;
  outline: none;
  padding: 0.525em 0.9em;
  letter-spacing: 0.015em;

  ${({ children }) =>
    children === 'Evening Entertainment' &&
    css`
      height: 60px !important;
      width: 176.39px !important;

      @media screen and (min-width: 366px) {
        height: initial !important;
        width: initial !important;
      }
    `}

  &:hover {
    background-color: rgba(77, 177, 232, 0.15);
  }

  ${({ active }) =>
    active &&
    css`
      animation: filterClickAnimation 0.225s ease-in-out forwards;
      background-color: var(--dynamic-blue);
      color: #fff;

      &:hover {
        background-color: var(--dynamic-blue);
      }
    `};

  @keyframes filterClickAnimation {
    0% {
      transform: scale3d(1, 1, 1);
    }

    40% {
      transform: scale3d(0.8, 0.8, 0.8);
    }

    60% {
      transform: scale3d(0.8, 0.8, 0.8);
    }

    100% {
      transform: scale3d(1, 1, 1);
    }
  }
`;

const KeywordInput = styled.input.attrs({
  type: 'text',
  name: 'search',
  placeholder: 'Search here',
  autoComplete: 'off'
})`
  border-style: none;
  border-bottom-color: var(--dynamic-blue);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: var(--dynamic-dark-grey);
  font-family: 'proxima-nova', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  height: 33px;
  margin-bottom: 21px;
  max-width: 100%;
  outline: none;
  text-align: center;
  width: 100%;

  &::placeholder {
    color: var(--dynamic-dark-grey);
    font-style: italic;
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: -5px;
    text-align: center;
  }
`;

const StyledBlobYellowRed = styled.img`
  position: absolute;
  top: 240px;
  left: 0;
`;

const StyledBlobBluePurple = styled.img`
  position: absolute;
  top: 960px;
  right: 0;
`;

const LightBlueBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  top: -33px;
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(BlogFiltersAndCards);
