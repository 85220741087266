import React, { PureComponent } from 'react';
import { Script } from 'gatsby';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import sizes from 'react-sizes';
import { isIE } from 'react-device-detect';
import { Footer, Header, HamburgerMenu, TeamBuildingToolboxPopup, EventManagementTrendsPopup } from 'components';
import { GlobalStyle } from 'shared_styles';

class Layout extends PureComponent {
  state = {
    makeHeaderSticky: false,
    hamBurgerMenuIsOpen: false,
    loadEventManagementTrendsModal: false
  };

  toggleHamburgerMenu = (e, userClickedOnLinkViaHamburgerMenu, link) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        hamBurgerMenuIsOpen: !prevState.hamBurgerMenuIsOpen
      };
    });
    if (userClickedOnLinkViaHamburgerMenu && link !== '#contact-us') {
      setTimeout(() => {
        navigate(link);
      }, 500);
    }
  };

  handleHeader = (userIsAtTopOfPage) => {
    this.setState((prevState) => ({
      makeHeaderSticky: !userIsAtTopOfPage && !prevState.makeHeaderSticky
    }));
  };

  handleEventManagementTrendsBannerLoading = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        loadEventManagementTrendsModal: !prevState.loadEventManagementTrendsModal
      };
    });
  }

  render() {
    const { children, viewportWidth } = this.props;
    const { hamBurgerMenuIsOpen, makeHeaderSticky, loadEventManagementTrendsModal } = this.state;

    const childrenExtended = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        handleHeader: this.handleHeader,
        handleEventManagementTrendsBannerLoading: this.handleEventManagementTrendsBannerLoading
      })
    );

    return (
      <>
        {viewportWidth < 1200 && (
          <HamburgerMenu
            hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}
            toggleHamburgerMenu={this.toggleHamburgerMenu}
          />
        )}
        <Wrapper>
          <GlobalStyle />
          <Header makeHeaderSticky={makeHeaderSticky} />
          <main
            style={{
              overflow: 'hidden'
            }}>
            {childrenExtended}
          </main>
          <Footer />
          <TeamBuildingToolboxPopup />
          {loadEventManagementTrendsModal && <EventManagementTrendsPopup />}
        </Wrapper>
      </>
    );
  }
}

const Wrapper = styled.div`
  background-color: #fff;
  margin: 0 auto;
  max-width: 2560px;
  overflow: hidden;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Layout);
